import { Box, IconButton, Link, Typography, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import React, { FC, MouseEventHandler, useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { useStoreMap } from 'effector-react';
import css from './styles.module.scss';
import { BootstrapTooltip } from '../../../../../components/BootstrapTooltip';
import { ContractItem, ErirStatus } from '../../../../../apiRPC/contracts';
import { $ordCatalog, $organizationData } from '../../model/catalogs';
import { generateContractPath } from '../../../../addresses';
import { navigateForEffect } from '../../../../../model/routing';
import { StatusMarker } from '../../../../components/StatusMarker';

function getErirStatus(
  erirStatus: ErirStatus | undefined,
  erirExportedOn?: number,
): string {
  if (!erirStatus) return '';
  const optionsTime: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const map: Record<ErirStatus, string> = {
    NONE: 'Не отправляется в ЕРИР',
    PENDING: `В ожидании выгрузки в ЕРИР${
      erirExportedOn
        ? `  •  ${new Intl.DateTimeFormat('ru', optionsTime).format(
            erirExportedOn * 1000,
          )}`
        : ''
    }`,
    PENDING_RESOURCE: `Ожидает выгрузки ресурсов в ЕРИР${
      erirExportedOn
        ? `  •  ${new Intl.DateTimeFormat('ru', optionsTime).format(
            erirExportedOn * 1000,
          )}`
        : ''
    }`,
    SUCCESS: `Экспортировано в ЕРИР${
      erirExportedOn
        ? `  •  ${new Intl.DateTimeFormat('ru', optionsTime).format(
            erirExportedOn * 1000,
          )}`
        : ''
    }`,
    ERROR: 'Ошибка',
  };

  return map?.[erirStatus] || '';
}

const optionsTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
type props = {
  row: ContractItem;
};

const Item: FC<props> = ({ row }) => {
  const [toggle, setToggle] = useState('');
  const theme = useTheme();

  const customer = useStoreMap({
    store: $organizationData,
    keys: [row.customer],
    fn: (state, keys) => state?.[keys[0]],
    updateFilter: (newResult, oldResult) => newResult?.name !== oldResult?.name,
  });
  const executor = useStoreMap({
    store: $organizationData,
    keys: [row.executor],
    fn: (state, keys) => state?.[keys[0]],
    updateFilter: (newResult, oldResult) => newResult?.name !== oldResult?.name,
  });
  const ord = useStoreMap({
    store: $ordCatalog,
    keys: [row.ord],
    fn: (state, key) => state.find((item) => item.id === key[0])?.title,
  });

  const onClickA: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigateForEffect(generateContractPath(row.uuid));
  };
  return (
    <>
      <TableCell component="th" scope="row" width="70px">
        <StatusMarker status={row.status} />
      </TableCell>
      <TableCell component="th" scope="row">
        <BootstrapTooltip
          title={getErirStatus(
            row.erirStatus,
            row.erirExportedOn || row.erirPlannedExportDate,
          )}
          placement="top"
        >
          <Box
            className={cn(css.status, {
              _isSuccess:
                row.erirStatus === 'SUCCESS' ||
                row.erirStatus === 'PENDING_RESOURCE',
              _isWarring: row.erirStatus === 'PENDING',
              _isError: row.erirStatus === 'ERROR',
            })}
          />
        </BootstrapTooltip>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        size="small"
        sx={{ width: 200, maxWidth: 'fit-content' }}
      >
        <Box
          sx={{
            maxWidth: '200px',
          }}
        >
          <BootstrapTooltip title={row.number} placement="top-start">
            <Box
              component={Link}
              href={generateContractPath(row.uuid)}
              className={css.name}
              onClick={onClickA}
            >
              {row.number}
            </Box>
          </BootstrapTooltip>
          <Typography
            sx={{
              color: theme.palette.secondary.light,
              fontSize: '14px',
              maxWidth: '300px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {row.uuid}
          </Typography>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row" width={70}>
        {new Intl.DateTimeFormat('ru', optionsTime).format(row.date * 1000)}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box
          sx={{
            maxwidth: '430px',
          }}
        >
          <BootstrapTooltip title={customer?.name} placement="top-start">
            <Box className={css.name}>{customer?.name}</Box>
          </BootstrapTooltip>
          <Typography
            sx={{
              color: theme.palette.secondary.light,
              fontSize: '14px',
              maxWidth: '300px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {customer?.inn}
          </Typography>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row">
        <Box
          sx={{
            maxWidth: '200px',
          }}
        >
          <BootstrapTooltip title={executor?.name} placement="top-start">
            <Box className={css.name}>{executor?.name}</Box>
          </BootstrapTooltip>
          <Typography
            sx={{
              color: theme.palette.secondary.light,
              fontSize: '14px',
              maxWidth: '300px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {executor?.inn}
          </Typography>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row" width={150}>
        {ord}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box
          className={cn(css.api, {
            _isSuccess: row.api,
          })}
        >
          {row.api ? 'Да' : 'Нет'}
        </Box>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          width: '57px',
        }}
      >
        <Box
          className={cn('dropDownRow', {
            _isOpen: toggle === row.uuid,
          })}
          onMouseEnter={() => {
            setToggle(row.uuid);
          }}
          onMouseLeave={() => {
            setToggle('');
          }}
        >
          <Box className="dropDownIcon">
            <FiMoreHorizontal size={24} color="#A6A6A6" />
          </Box>
          <Box className="dropDownMenu">
            {toggle === row.uuid && (
              <NavLink to={generateContractPath(row.uuid)}>
                <IconButton title="Редактировать" color="primary">
                  <ModeEditOutlinedIcon color="primary" />
                </IconButton>
              </NavLink>
            )}
          </Box>
        </Box>
      </TableCell>
    </>
  );
};

export default Item;
