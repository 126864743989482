export function getValCategories(
  val: string | string[] | undefined,
  options: { value: string; label: string }[],
) {
  if (!val) return undefined;
  const catigoria = options.find((item) => item.value === val);
  if (catigoria) return catigoria;
  if (Array.isArray(val)) {
    return val[0];
  }
  return val;
}
