export const categories = [
  {
    value: '1.1.1',
    label: 'ВИНО',
  },
  {
    value: '1.1.2',
    label: 'КРЕПКИЕ АЛКОГОЛЬНЫЕ НАПИТКИ',
  },
  {
    value: '1.1.3',
    label: 'АЛКОГОЛЬНЫЕ НАПИТКИ (РАЗНОЕ)',
  },
  {
    value: '1.1.4',
    label: 'СЛАБОАЛКОГОЛЬНЫЕ НАПИТКИ',
  },
  {
    value: '1.2.1',
    label: 'ТАБАЧНЫЕ ИЗДЕЛИЯ',
  },
  {
    value: '2.1.1',
    label: 'АУДИОАППАРАТУРА',
  },
  {
    value: '2.1.2',
    label: 'ТВ И ВИДЕОАППАРАТУРА',
  },
  {
    value: '2.1.3',
    label: 'ФОТОАППАРАТУРА',
  },
  {
    value: '2.1.4',
    label: 'АУДИО, ВИДЕО, КИНО И ФОТОТЕХНИКА (ПРОЧЕЕ)',
  },
  {
    value: '3.1.1',
    label: 'СТИРАЛЬНЫЕ МАШИНЫ',
  },
  {
    value: '3.1.2',
    label: 'ХОЛОДИЛЬНИКИ',
  },
  {
    value: '3.1.3',
    label: 'КРУПНАЯ БЫТОВАЯ ТЕХНИКА (ПРОЧЕЕ)',
  },
  {
    value: '3.2.1',
    label: 'МЕЛКАЯ И СРЕДНЯЯ БЫТОВАЯ ТЕХНИКА',
  },
  {
    value: '4.1.1',
    label: 'СРЕДСТВА ДЛЯ МЫТЬЯ ПОСУДЫ',
  },
  {
    value: '4.1.2',
    label: 'СРЕДСТВА ДЛЯ СТИРКИ',
  },
  {
    value: '4.1.3',
    label: 'ЧИСТЯЩИЕ СРЕДСТВА',
  },
  {
    value: '4.1.4',
    label: 'МОЮЩИЕ И ЧИСТЯЩИЕ СРЕДСТВА (ПРОЧЕЕ)',
  },
  {
    value: '4.2.1',
    label: 'СРЕДСТВА БОРЬБЫ С НАСЕКОМЫМИ',
  },
  {
    value: '4.3.1',
    label: 'СР-ВА ПО УХОДУ ЗА ОДЕЖДОЙ И ОБУВЬЮ',
  },
  {
    value: '4.3.2',
    label: 'БЫТОВАЯ ХИМИЯ (ЯДОХИМИКАТЫ)',
  },
  {
    value: '4.3.3',
    label: 'БЫТОВАЯ ХИМИЯ (ПРОЧЕЕ)',
  },
  {
    value: '5.1.1',
    label: 'ЛОТЕРЕИ И РОЗЫГРЫШИ ПРИЗОВ',
  },
  {
    value: '5.1.2',
    label: 'УСЛУГИ БУКМЕКЕРСКИЕ',
  },
  {
    value: '5.2.1',
    label: 'ДОСУГ',
  },
  {
    value: '5.2.2',
    label: 'КИНОТЕАТРЫ',
  },
  {
    value: '5.2.3',
    label: 'КОНКУРСЫ, ВИКТОРИНЫ',
  },
  {
    value: '5.2.4',
    label: 'КУЛЬТУРНЫЕ И РАЗВЛЕКАТЕЛЬНЫЕ УЧРЕЖДЕНИЯ',
  },
  {
    value: '5.2.5',
    label: 'РАЗВЛЕКАТЕЛЬНЫЕ ЗРЕЛИЩА',
  },
  {
    value: '5.2.6',
    label: 'СПЕЦИАЛИЗИРОВАННЫЕ МЕРОПРИЯТИЯ',
  },
  {
    value: '5.2.7',
    label: 'ПРАЗДНИЧНЫЕ ТОВАРЫ',
  },
  {
    value: '5.2.8',
    label: 'ИГРОВЫЕ АВТОМАТЫ (B2B)',
  },
  {
    value: '5.2.9',
    label: 'ИНФОРМАЦИОННО-РАЗВЛЕКАТЕЛЬНЫЕ УСЛУГИ СОТОВОЙ СВЯЗИ',
  },
  {
    value: '5.2.10',
    label: 'ИНФОРМАЦИОННОЕ СООБЩЕНИЕ',
  },
  {
    value: '5.2.11',
    label: 'ДОСУГ И РАЗВЛЕЧЕНИЯ (ПРОЧЕЕ)',
  },
  {
    value: '5.3.1',
    label: 'ТУРИЗМ И ОТДЫХ',
  },
  {
    value: '6.1.1',
    label: 'ИНТЕРНЕТ-СЕРВИСЫ',
  },
  {
    value: '7.1.1',
    label: 'МАРКЕТПЛЕЙСЫ',
  },
  {
    value: '7.1.2',
    label: 'ИНТЕРНЕТ-ТОРГОВЛЯ (ПРОЧЕЕ)',
  },
  {
    value: '8.1.1',
    label: 'КАФЕ И РЕСТОРАНЫ',
  },
  {
    value: '9.1.1',
    label: 'КОМПЬЮТЕРЫ',
  },
  {
    value: '9.1.2',
    label: 'ПОРТАТИВНЫЕ КОМПЬЮТЕРЫ',
  },
  {
    value: '9.1.3',
    label: 'КОМПЬЮТЕРНАЯ ПЕРИФЕРИЯ',
  },
  {
    value: '9.1.4',
    label: 'КОМПЬЮТЕРНЫЕ КОМПЛЕКТУЮЩИЕ',
  },
  {
    value: '9.1.5',
    label: 'ОФИСНАЯ ТЕХНИКА',
  },
  {
    value: '9.1.6',
    label: 'СИСТЕМЫ НА БАЗЕ КОМПЬЮТЕРНОЙ ТЕХНИКИ',
  },
  {
    value: '9.1.7',
    label: 'КАЛЬКУЛЯТОРЫ, ЭЛЕКТРОННЫЕ ЗАПИСНЫЕ КНИЖКИ',
  },
  {
    value: '9.1.8',
    label: 'КОМПЬЮТЕРЫ И ОРГТЕХНИКА (ПРОЧЕЕ)',
  },
  {
    value: '9.2.1',
    label: 'ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ',
  },
  {
    value: '10.1.1',
    label: 'ЛЕГКОВЫЕ АВТОМОБИЛИ',
  },
  {
    value: '10.2.1',
    label: 'ГРУЗОВЫЕ АВТОМОБИЛИ И ПИКАПЫ',
  },
  {
    value: '10.2.2',
    label: 'АВТОБУСЫ И МИКРОАВТОБУСЫ',
  },
  {
    value: '10.3.1',
    label: 'МОТОТЕХНИКА',
  },
  {
    value: '10.4.1',
    label: 'ВОДНЫЙ ТРАНСПОРТ',
  },
  {
    value: '10.4.2',
    label: 'СНЕГОХОДЫ, ВЕЗДЕХОДЫ',
  },
  {
    value: '10.5.1',
    label: 'АВТОТРАНСПОРТ (ПРОЧЕЕ)',
  },
  {
    value: '10.6.1',
    label: 'ТРАКТОРЫ, СТРОИТЕЛЬНАЯ И ДОРОЖНАЯ ТЕХНИКА',
  },
  {
    value: '10.6.2',
    label: 'СЕЛЬСКОХОЗЯЙСТВЕННАЯ ТЕХНИКА',
  },
  {
    value: '10.6.3',
    label: 'ТЕХНИКА ДЛЯ КОММУНАЛЬНОГО ХОЗЯЙСТВА',
  },
  {
    value: '10.7.1',
    label: 'ТРАНСПОРТНЫЕ СРЕДСТВА (ПРОЧЕЕ)',
  },
  {
    value: '10.8.1',
    label: 'АВТОСЕРВИС',
  },
  {
    value: '10.9.1',
    label: 'АВТОАКСЕССУАРЫ',
  },
  {
    value: '10.9.2',
    label: 'АВТОЗАПЧАСТИ',
  },
  {
    value: '10.9.3',
    label: 'АВТОМОБИЛЬНАЯ АУДИОТЕХНИКА',
  },
  {
    value: '10.9.4',
    label: 'АВТОМОБИЛЬНАЯ БЫТОВАЯ ТЕХНИКА',
  },
  {
    value: '10.9.5',
    label: 'АВТОХИМИЯ',
  },
  {
    value: '10.9.6',
    label: 'АВТОШИНЫ',
  },
  {
    value: '10.9.7',
    label: 'АВТОЭЛЕКТРОНИКА (РАЗНОЕ)',
  },
  {
    value: '10.9.8',
    label: 'ГСМ И РАСХОДНЫЕ МАТЕРИАЛЫ',
  },
  {
    value: '10.9.9',
    label: 'МОТОРНОЕ МАСЛО',
  },
  {
    value: '10.9.10',
    label: 'ОБОРУДОВАНИЕ ДЛЯ АВТОТЕХНИКИ',
  },
  {
    value: '11.1.1',
    label: 'МЕБЕЛЬ',
  },
  {
    value: '11.1.2',
    label: 'ПРЕДМЕТЫ ИНТЕРЬЕРА',
  },
  {
    value: '11.1.3',
    label: 'КУХОННЫЕ И ХОЗЯЙСТВЕННЫЕ ПРИНАДЛЕЖНОСТИ',
  },
  {
    value: '12.1.1',
    label: 'МЕДИЦИНСКИЕ МАТЕРИАЛЫ И ОБОРУДОВАНИЕ',
  },
  {
    value: '12.1.2',
    label: 'МЕДИЦИНСКИЕ УСЛУГИ',
  },
  {
    value: '12.1.3',
    label: 'ОПТИКА',
  },
  {
    value: '12.2.1',
    label: 'БИОЛОГИЧЕСКИЕ ПИЩЕВЫЕ ДОБАВКИ',
  },
  {
    value: '12.2.2',
    label: 'ЛЕКАРСТВЕННЫЕ ПРЕПАРАТЫ',
  },
  {
    value: '12.2.3',
    label: 'СПЕЦИАЛЬНЫЕ ЛЕКАРСТВЕННЫЕ СРЕДСТВА',
  },
  {
    value: '12.2.4',
    label: 'ПРЕЗЕРВАТИВЫ',
  },
  {
    value: '12.2.5',
    label: 'ФАРМАЦЕВТИЧЕСКИЕ ТОВАРЫ (ПРОЧЕЕ)',
  },
  {
    value: '13.1.1',
    label: 'ГАРАЖИ И ГАРАЖНОЕ ОБОРУДОВАНИЕ',
  },
  {
    value: '13.1.2',
    label: 'ЖИЛАЯ НЕДВИЖИМОСТЬ',
  },
  {
    value: '13.1.3',
    label: 'ЗАГОРОДНАЯ НЕДВИЖИМОСТЬ',
  },
  {
    value: '13.1.4',
    label: 'НЕДВИЖИМОСТЬ (РАЗНОЕ)',
  },
  {
    value: '13.1.5',
    label: 'НЕЖИЛАЯ НЕДВИЖИМОСТЬ',
  },
  {
    value: '14.1.1',
    label: 'ДЕТСКАЯ ОДЕЖДА',
  },
  {
    value: '14.1.2',
    label: 'ЖЕНСКАЯ ОДЕЖДА',
  },
  {
    value: '14.1.3',
    label: 'МУЖСКАЯ ОДЕЖДА',
  },
  {
    value: '14.1.4',
    label: 'СПОРТИВНАЯ ОДЕЖДА',
  },
  {
    value: '14.1.5',
    label: 'НИЖНЕЕ БЕЛЬЕ',
  },
  {
    value: '14.1.6',
    label: 'ОДЕЖДА (ПРОЧЕЕ)',
  },
  {
    value: '14.1.7',
    label: 'ДЕТСКАЯ ОБУВЬ',
  },
  {
    value: '14.1.8',
    label: 'ЖЕНСКАЯ ОБУВЬ',
  },
  {
    value: '14.1.9',
    label: 'МУЖСКАЯ ОБУВЬ',
  },
  {
    value: '14.1.10',
    label: 'СПОРТИВНАЯ ОБУВЬ',
  },
  {
    value: '14.1.11',
    label: 'ОБУВЬ (РАЗНОЕ)',
  },
  {
    value: '14.1.12',
    label: 'ОДЕЖДА И ОБУВЬ (РАЗНОЕ)',
  },
  {
    value: '15.1.1',
    label: 'ДЕТСКИЙ ШАМПУНЬ',
  },
  {
    value: '15.1.2',
    label: 'СРЕДСТВА ПО УХОДУ ЗА ВОЛОСАМИ',
  },
  {
    value: '15.1.3',
    label: 'ШАМПУНЬ',
  },
  {
    value: '15.1.4',
    label: 'СРЕДСТВА ПО УХОДУ ЗА ВОЛОСАМИ (ПРОЧЕЕ)',
  },
  {
    value: '15.2.1',
    label: 'ГЕЛЬ ДЛЯ ДУША',
  },
  {
    value: '15.2.2',
    label: 'МЫЛО',
  },
  {
    value: '15.2.3',
    label: 'СРЕДСТВА ДЛЯ БРИТЬЯ И ЭПИЛЯЦИИ (РАЗНОЕ)',
  },
  {
    value: '15.2.4',
    label: 'СРЕДСТВА ДЛЯ И ПОСЛЕ БРИТЬЯ',
  },
  {
    value: '15.2.5',
    label: 'СРЕДСТВА ДЛЯ УДАЛЕНИЯ ВОЛОС',
  },
  {
    value: '15.2.6',
    label: 'СРЕДСТВА ПО УХОДУ ЗА КОЖЕЙ',
  },
  {
    value: '15.3.1',
    label: 'ДЕЗОДОРАНТЫ',
  },
  {
    value: '15.3.2',
    label: 'ДЕКОРАТИВНАЯ КОСМЕТИКА',
  },
  {
    value: '15.3.3',
    label: 'ПАРФЮМЕРИЯ',
  },
  {
    value: '15.3.4',
    label: 'СРЕДСТВА ПО УХОДУ ЗА НОГТЯМИ',
  },
  {
    value: '15.3.5',
    label: 'ТОВАРЫ ДЛЯ КРАСОТЫ И ЗДОРОВЬЯ (РАЗНОЕ)',
  },
  {
    value: '16.1.1',
    label: 'ПИВО',
  },
  {
    value: '16.1.2',
    label: 'ПИВО (ПРОЧЕЕ)',
  },
  {
    value: '17.1.1',
    label: 'ЙОГУРТЫ',
  },
  {
    value: '17.1.2',
    label: 'МАСЛОЖИРОВАЯ ПРОДУКЦИЯ',
  },
  {
    value: '17.1.3',
    label: 'МОЛОКО, МОЛОЧНАЯ ПРОДУКЦИЯ',
  },
  {
    value: '17.2.1',
    label: 'БУЛЬОННЫЕ КУБИКИ',
  },
  {
    value: '17.2.2',
    label: 'КАШИ БЫСТРОГО ПРИГОТОВЛЕНИЯ',
  },
  {
    value: '17.2.3',
    label: 'ПРОДУКТЫ БЫСТРОГО ПРИГОТОВЛЕНИЯ',
  },
  {
    value: '17.3.1',
    label: 'КОФЕ',
  },
  {
    value: '17.3.2',
    label: 'КАКАО',
  },
  {
    value: '17.3.3',
    label: 'КОФЕ И КАКАО (РАЗНОЕ)',
  },
  {
    value: '17.3.4',
    label: 'ЧАЙ',
  },
  {
    value: '17.4.1',
    label: 'ШОКОЛАДНЫЕ БАТОНЧИКИ',
  },
  {
    value: '17.4.2',
    label: 'ШОКОЛАДНЫЕ КОНФЕТЫ В КОРОБКАХ',
  },
  {
    value: '17.4.3',
    label: 'ШОКОЛАД И ШОКОЛАДНЫЕ ИЗДЕЛИЯ (ПРОЧЕЕ)',
  },
  {
    value: '17.5.1',
    label: 'БАКАЛЕЯ И КУЛИНАРИЯ',
  },
  {
    value: '17.5.2',
    label: 'ДЕТСКОЕ ПИТАНИЕ',
  },
  {
    value: '17.5.3',
    label: 'ЖЕВАТЕЛЬНАЯ РЕЗИНКА',
  },
  {
    value: '17.5.4',
    label: 'КОНДИТЕРСКИЕ ИЗДЕЛИЯ (РАЗНОЕ)',
  },
  {
    value: '17.5.5',
    label: 'ЛЕГКИЕ ЗАКУСКИ (SNACKS)',
  },
  {
    value: '17.5.6',
    label: 'МОРОЖЕНОЕ',
  },
  {
    value: '17.5.7',
    label: 'ПРИПРАВЫ И СПЕЦИИ, ВКУСОВЫЕ ДОБАВКИ',
  },
  {
    value: '17.5.8',
    label: 'ПРОДУКТЫ БЫСТРОЙ ЗАМОРОЗКИ',
  },
  {
    value: '17.5.9',
    label: 'РАСТИТЕЛЬНОЕ МАСЛО',
  },
  {
    value: '17.5.10',
    label: 'СОУСЫ, МАЙОНЕЗ',
  },
  {
    value: '17.5.11',
    label: 'ПРОДУКТЫ ПИТАНИЯ (ПРОЧЕЕ)',
  },
  {
    value: '18.1.1',
    label: 'ГАЗИРОВАННЫЕ НАПИТКИ',
  },
  {
    value: '18.1.2',
    label: 'МИНЕРАЛЬНАЯ И ОЧИЩЕННАЯ ПИТЬЕВАЯ ВОДА',
  },
  {
    value: '18.1.3',
    label: 'ПРОХЛАДИТЕЛЬНЫЕ НАПИТКИ (ПРОЧЕЕ)',
  },
  {
    value: '18.2.1',
    label: 'ЭНЕРГЕТИЧЕСКИЕ НАПИТКИ',
  },
  {
    value: '18.3.1',
    label: 'СОКИ',
  },
  {
    value: '18.3.2',
    label: 'ДЕТСКИЕ СОКИ',
  },
  {
    value: '18.3.3',
    label: 'СОКИ (ПРОЧЕЕ)',
  },
  {
    value: '19.1.1',
    label: 'БАНКОВСКАЯ ТЕХНИКА',
  },
  {
    value: '19.1.2',
    label: 'ПРОМЫШЛЕННОЕ, СКЛАДСКОЕ И ТОРГОВОЕ ОБОРУДОВАНИЕ',
  },
  {
    value: '19.1.3',
    label: 'ПРОМЫШЛЕННОЕ СЫРЬЕ И МАТЕРИАЛЫ',
  },
  {
    value: '19.1.4',
    label: 'УПАКОВОЧНЫЕ МАТЕРИАЛЫ',
  },
  {
    value: '19.1.5',
    label: 'ОБОРУДОВАНИЕ И ТЕХНОЛОГИЧЕСКИЕ МАТЕРИАЛЫ (ПРОЧЕЕ)',
  },
  {
    value: '19.2.1',
    label: 'ПРОИЗВОДСТВЕННЫЕ УСЛУГИ ',
  },
  {
    value: '20.1.1',
    label: 'СМИ (ИНТЕРНЕТ)',
  },
  {
    value: '20.1.2',
    label: 'СМИ (ПЕЧАТНЫЕ ИЗДАНИЯ)',
  },
  {
    value: '20.1.3',
    label: 'СМИ (РАДИО)',
  },
  {
    value: '20.1.4',
    label: 'СМИ (ТЕЛЕВИДЕНИЕ)',
  },
  {
    value: '20.1.5',
    label: 'СМИ (РАЗНОЕ)',
  },
  {
    value: '21.1.1',
    label: 'ПОЛИТИЧЕСКАЯ РЕКЛАМА',
  },
  {
    value: '21.2.1',
    label: 'СОЦИАЛЬНАЯ РЕКЛАМА',
  },
  {
    value: '22.1.1',
    label: 'БРИТВЕННЫЕ СИСТЕМЫ',
  },
  {
    value: '22.2.1',
    label: 'ГИГИЕНИЧЕСКИЕ ПРОКЛАДКИ, ТАМПОНЫ',
  },
  {
    value: '22.2.2',
    label: 'СРЕДСТВА ГИГИЕНЫ ДЛЯ ЖЕНЩИН',
  },
  {
    value: '22.2.3',
    label: 'ПОДГУЗНИКИ',
  },
  {
    value: '22.2.4',
    label: 'СРЕДСТВА ГИГИЕНЫ ДЛЯ ДЕТЕЙ',
  },
  {
    value: '22.2.5',
    label: 'СРЕДСТВА И ПРЕДМЕТЫ ГИГИЕНЫ (ПРОЧЕЕ)',
  },
  {
    value: '22.3.1',
    label: 'ЗУБНАЯ ПАСТА',
  },
  {
    value: '22.3.2',
    label: 'ЗУБНЫЕ ЩЕТКИ',
  },
  {
    value: '22.3.3',
    label: 'СРЕДСТВА ДЛЯ ГИГИЕНЫ РТА',
  },
  {
    value: '22.3.4',
    label: 'СРЕДСТВА ГИГИЕНЫ (ПРОЧЕЕ)',
  },
  {
    value: '23.1.1',
    label: 'СОТОВЫЕ ТЕЛЕФОНЫ',
  },
  {
    value: '23.1.2',
    label: 'МОБИЛЬНЫЕ УСТРОЙСТВА СВЯЗИ (ПРОЧЕЕ)',
  },
  {
    value: '23.2.1',
    label: 'ОБОРУДОВАНИЕ ДЛЯ СВЯЗИ',
  },
  {
    value: '24.1.1',
    label: 'ОКНА И РАМЫ',
  },
  {
    value: '24.1.2',
    label: 'ОТДЕЛОЧНЫЕ МАТЕРИАЛЫ',
  },
  {
    value: '24.1.3',
    label: 'ОТОПИТЕЛЬНОЕ ОБОРУДОВАНИЕ',
  },
  {
    value: '24.1.4',
    label: 'САНТЕХНИКА',
  },
  {
    value: '24.1.5',
    label: 'САНТЕХНИЧЕСКОЕ ОБОРУДОВАНИЕ',
  },
  {
    value: '24.1.6',
    label: 'СТРОИТЕЛЬНЫЕ ИНСТРУМЕНТЫ И ОБОРУДОВАНИЕ',
  },
  {
    value: '24.1.7',
    label: 'СТРОИТЕЛЬНЫЕ КОНСТРУКЦИИ',
  },
  {
    value: '24.1.8',
    label: 'СТРОИТЕЛЬНЫЕ МАТЕРИАЛЫ',
  },
  {
    value: '24.1.9',
    label: 'ЭЛЕКТРООБОРУДОВАНИЕ',
  },
  {
    value: '24.1.10',
    label: 'СТРОЙМАТЕРИАЛЫ И ОБОРУДОВАНИЕ (ПРОЧЕЕ)',
  },
  {
    value: '24.2.1',
    label: 'СТРОИТЕЛЬСТВО И РЕМОНТ',
  },
  {
    value: '25.1.1',
    label: 'УСЛУГИ СОТОВОЙ СВЯЗИ',
  },
  {
    value: '25.1.2',
    label: 'ИНТЕРНЕТ-ПРОВАЙДЕР',
  },
  {
    value: '25.1.3',
    label: 'КАРТЫ ДЛЯ ОПЛАТЫ УСЛУГ СВЯЗИ',
  },
  {
    value: '25.1.4',
    label: 'КОМПЬЮТЕРНЫЕ СЕТИ',
  },
  {
    value: '25.1.5',
    label: 'УСЛУГИ СВЯЗИ (ПРОЧЕЕ)',
  },
  {
    value: '25.2.1',
    label: 'ТЕЛЕКОММУНИКАЦИОННОЕ ОБОРУДОВАНИЕ',
  },
  {
    value: '26.1.1',
    label: 'ДЕТСКИЕ КОЛЯСКИ',
  },
  {
    value: '26.2.1',
    label: 'ИГРУШКИ',
  },
  {
    value: '26.2.2',
    label: 'ИГРЫ',
  },
  {
    value: '26.3.1',
    label: 'СРЕДСТВА ДЕТСКОЙ ГИГИЕНЫ',
  },
  {
    value: '26.4.1',
    label: 'ДЕТСКИЕ АВТОКРЕСЛА',
  },
  {
    value: '26.4.2',
    label: 'ПОСТЕЛЬНЫЕ ПРИНАДЛЕЖНОСТИ ДЛЯ ДЕТЕЙ',
  },
  {
    value: '26.4.3',
    label: 'ПРИНАДЛЕЖНОСТИ ДЛЯ КОРМЛЕНИЯ ДЕТЕЙ',
  },
  {
    value: '26.4.4',
    label: 'ПРИНАДЛЕЖНОСТИ ПО УХОДУ ЗА ДЕТЬМИ',
  },
  {
    value: '26.4.5',
    label: 'ТОВАРЫ ДЛЯ ДЕТЕЙ (ПРОЧЕЕ)',
  },
  {
    value: '27.1.1',
    label: 'КОРМ ДЛЯ ДОМАШНИХ ЖИВОТНЫХ',
  },
  {
    value: '27.1.2',
    label: 'ПРОДАЖА: КОРМА ДЛЯ СЕЛЬСКОГО ХОЗЯЙСТВА',
  },
  {
    value: '27.2.1',
    label: 'ТОВАРЫ ДЛЯ ЖИВОТНЫХ (ПРОЧЕЕ)',
  },
  {
    value: '28.1.1',
    label: 'АПТЕКИ И ОПТИКИ',
  },
  {
    value: '28.1.2',
    label: 'МАГАЗИНЫ АВТО- МОТОТЕХНИКИ, ЗАПЧАСТЕЙ И АКСЕССУАРОВ',
  },
  {
    value: '28.1.3',
    label: 'МАГАЗИНЫ БЫТОВОЙ ТЕХНИКИ И ЭЛЕКТРОНИКИ',
  },
  {
    value: '28.1.4',
    label: 'МАГАЗИНЫ МЕБЕЛИ И ТОВАРОВ ДЛЯ ДОМА',
  },
  {
    value: '28.1.5',
    label: 'МАГАЗИНЫ МЕХОВ И КОЖАНОЙ ОДЕЖДЫ',
  },
  {
    value: '28.1.6',
    label: 'МАГАЗИНЫ ОДЕЖДЫ И ОБУВИ',
  },
  {
    value: '28.1.7',
    label: 'МАГАЗИНЫ ПО ПРОДАЖЕ ПАРФЮМЕРИИ И КОСМЕТИКИ',
  },
  {
    value: '28.1.8',
    label: 'МАГАЗИНЫ ПРОДУКТОВЫЕ',
  },
  {
    value: '28.1.9',
    label: 'МАГАЗИНЫ СПОРТИВНЫХ ТОВАРОВ',
  },
  {
    value: '28.1.10',
    label: 'МАГАЗИНЫ ТОВАРОВ ДЛЯ СТРОИТЕЛЬСТВА И РЕМОНТА',
  },
  {
    value: '28.1.11',
    label: 'МАГАЗИНЫ ЮВЕЛИРНЫХ ИЗДЕЛИЙ',
  },
  {
    value: '28.1.12',
    label: 'САЛОНЫ СОТОВОЙ СВЯЗИ',
  },
  {
    value: '28.1.13',
    label: 'ТОРГОВЫЕ ЦЕНТРЫ И ТОРГОВЫЕ ДОМА',
  },
  {
    value: '28.1.14',
    label: 'ЯРМАРКИ, ВЫСТАВКА-ПРОДАЖА',
  },
  {
    value: '28.1.15',
    label: 'ТОРГОВЫЕ ОРГАНИЗАЦИИ (ПРОЧЕЕ)',
  },
  {
    value: '29.1.1',
    label: 'АВТОСТРАХОВАНИЕ',
  },
  {
    value: '29.1.2',
    label: 'СТРАХОВЫЕ УСЛУГИ ДЛЯ ФИЗИЧЕСКИХ ЛИЦ',
  },
  {
    value: '29.1.3',
    label: 'СТРАХОВЫЕ УСЛУГИ ДЛЯ ЮРИДИЧЕСКИХ ЛИЦ',
  },
  {
    value: '29.1.4',
    label: 'СТРАХОВЫЕ УСЛУГИ (ПРОЧЕЕ)',
  },
  {
    value: '29.2.1',
    label: 'БАНКОВСКИЕ ВКЛАДЫ',
  },
  {
    value: '29.2.2',
    label: 'КРЕДИТОВАНИЕ ФИЗИЧЕСКИХ ЛИЦ',
  },
  {
    value: '29.2.3',
    label: 'КРЕДИТОВАНИЕ ЮРИДИЧЕСКИХ ЛИЦ',
  },
  {
    value: '29.2.4',
    label: 'ОПЕРАЦИИ С ЦЕННЫМИ БУМАГАМИ',
  },
  {
    value: '29.2.5',
    label: 'ПАЕВЫЕ ИНВЕСТИЦИОННЫЕ ФОНДЫ',
  },
  {
    value: '29.2.6',
    label: 'ПЛАТЕЖНЫЕ СИСТЕМЫ, ПЛАСТИКОВЫЕ КАРТЫ',
  },
  {
    value: '29.2.7',
    label: 'УСЛУГИ БАНКОВ (ПРОЧЕЕ)',
  },
  {
    value: '29.2.8',
    label: 'УСЛУГИ ПЕНСИОННЫХ ФОНДОВ',
  },
  {
    value: '29.2.9',
    label: 'ФИНАНСОВЫЕ УСЛУГИ (ПРОЧЕЕ)',
  },
  {
    value: '30.1.1',
    label: 'АУДИО-, ВИДЕОПРОДУКЦИЯ, ИГРЫ',
  },
  {
    value: '30.1.2',
    label: 'ЭЛЕКТРОННЫЕ ВЕРСИИ ПЕЧАТНЫХ ИЗДАНИЙ',
  },
  {
    value: '30.1.3',
    label: 'ЭЛЕКТРОННЫЕ ИЗДАНИЯ',
  },
  {
    value: '30.2.1',
    label: 'ПРОКАТ',
  },
  {
    value: '30.2.2',
    label: 'ПОШИВ ОДЕЖДЫ И ОБУВИ',
  },
  {
    value: '30.2.3',
    label: 'БЫТОВОЙ РЕМОНТ',
  },
  {
    value: '30.2.4',
    label: 'УСЛУГИ ХИМЧИСТОК  И ПРАЧЕЧНЫХ',
  },
  {
    value: '30.2.5',
    label: 'УСЛУГИ ФОТО, КИНО, ВИДЕО, АУДИО',
  },
  {
    value: '30.2.6',
    label: 'УСЛУГИ ПАРИКМАХЕРСКИХ И КОСМЕТИЧ. САЛОНОВ',
  },
  {
    value: '30.2.7',
    label: 'ДОСТАВКА, КУРЬЕРЫ,ЭКСПЕДИТОРЫ',
  },
  {
    value: '30.2.8',
    label: 'УСЛУГИ ИНФОРМАЦИОННЫЕ',
  },
  {
    value: '30.2.9',
    label: 'УСЛУГИ ПО УБОРКЕ ОФИСОВ И КВАРТИР',
  },
  {
    value: '30.2.10',
    label: 'УСЛУГИ ЖИЛИЩНО-КОММУНАЛЬНЫЕ',
  },
  {
    value: '30.2.11',
    label: 'УСЛУГИ БЫТОВЫЕ И СЕРВИС (ПРОЧЕЕ)',
  },
  {
    value: '30.3.1',
    label: 'ЗАЖИГАЛКИ, КУРИТЕЛЬНЫЕ ПРИНАДЛЕЖНОСТИ',
  },
  {
    value: '30.3.2',
    label: 'ТЕКСТИЛЬНАЯ ГАЛАНТЕРЕЯ',
  },
  {
    value: '30.3.3',
    label: 'СУМКИ',
  },
  {
    value: '30.3.4',
    label: 'ГАЛАНТЕРЕЯ И АКСЕССУАРЫ (ПРОЧЕЕ)',
  },
  {
    value: '30.3.5',
    label: 'МУЗЫКАЛЬНЫЕ ИНСТРУМЕНТЫ',
  },
  {
    value: '30.4.1',
    label: 'КАНЦТОВАРЫ',
  },
  {
    value: '30.4.2',
    label: 'КНИГИ И ДРУГАЯ ПОЛИГРАФИЯ',
  },
  {
    value: '30.5.1',
    label: 'ОБРАЗОВАТЕЛЬНЫЕ УСЛУГИ',
  },
  {
    value: '30.5.2',
    label: 'УСЛУГИ ПО ТРУДОУСТРОЙСТВУ',
  },
  {
    value: '30.6.1',
    label: 'ОРУЖИЕ И СРЕДСТВА ЗАЩИТЫ',
  },
  {
    value: '30.6.2',
    label: 'ТЕХНИЧЕСКИЕ СРЕДСТВА БЕЗОПАСНОСТИ',
  },
  {
    value: '30.6.3',
    label: 'УСЛУГИ ПО ОХРАНЕ И БЕЗОПАСНОСТИ',
  },
  {
    value: '30.7.1',
    label: 'ОХОТНИЧЬИ И РЫБОЛОВНЫЕ ТОВАРЫ',
  },
  {
    value: '30.8.1',
    label: 'РАСТЕНИЯ, РАССАДА, СЕМЕНА',
  },
  {
    value: '30.8.2',
    label: 'САДОВАЯ ТЕХНИКА',
  },
  {
    value: '30.8.3',
    label: 'АГРОХИМИЯ, ПОЧВОГРУНТЫ, УДОБРЕНИЯ',
  },
  {
    value: '30.8.4',
    label: 'ТОВАРЫ ДЛЯ САДА И ОГОРОДА (ПРОЧЕЕ)',
  },
  {
    value: '30.9.1',
    label: 'ТРАНСПОРТНЫЕ УСЛУГИ',
  },
  {
    value: '30.10.1',
    label: 'РАЗМЕЩЕНИЕ РЕКЛАМЫ',
  },
  {
    value: '30.10.2',
    label: 'ИЗГОТОВЛЕНИЕ РЕКЛАМЫ',
  },
  {
    value: '30.10.3',
    label: 'УСЛУГИ В ОБЛ. РЕКЛАМЫ И МАРКЕТИНГА (ПРОЧЕЕ) (B2B)',
  },
  {
    value: '30.11.1',
    label: 'УСЛУГИ В ОБЛАСТИ ФИЗКУЛЬТУРЫ И СПОРТА',
  },
  {
    value: '30.11.2',
    label: 'СПОРТИВНЫЙ ИНВЕНТАРЬ',
  },
  {
    value: '30.12.1',
    label: 'ЦВЕТЫ И ПРОЧИЕ РАСТЕНИЯ',
  },
  {
    value: '30.13.1',
    label: 'ЧАСЫ',
  },
  {
    value: '30.13.2',
    label: 'ЮВЕЛИРНЫЕ ИЗДЕЛИЯ',
  },
  {
    value: '30.14.1',
    label: 'ЮРИДИЧЕСКИЕ, АУДИТОРСКИЕ И КОНСАЛТИНГОВЫЕ УСЛУГИ',
  },
  {
    value: '30.15.1',
    label: 'ПРОЧИЕ ТОВАРЫ И УСЛУГИ',
  },
];
