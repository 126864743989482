import { Box } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import css from './styles.module.scss';
import { BootstrapTooltip } from '../../../components/BootstrapTooltip';

type StatusMarkerProps = {
  status: 'ACTIVE' | 'DELETED' | 'ON_APPROVAL';
};

const mapStatusText = {
  ACTIVE: 'Активен',
  DELETED: 'Удален',
  ON_APPROVAL: 'На модерации',
};

export const StatusMarker: React.FC<StatusMarkerProps> = ({ status }) => (
  <BootstrapTooltip title={mapStatusText[status] || status} placement="top">
    <Box
      className={cn(css.status, {
        _isSuccess: status === 'ACTIVE',
        _isWarring: status === 'ON_APPROVAL',
        _isError: status === 'DELETED',
      })}
    />
  </BootstrapTooltip>
);
