import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import { useUnit } from 'effector-react';
import Header from '../../../../components/Header';
import { addressesORD } from '../../../../addresses';
import Search from '../../../../../components/Search';
import { OtherFilters } from '../OtherFilters';
import SelectField from '../../../../../UI/form/SelectField';
import Import from '../Import';

import { $searchFilter, onSetSearchFilter } from '../model/filters/search';
import {
  $copyCreatives,
  $isPendingCopyCreatives,
  onCopyCreatives,
  resetSelectedCreatives,
} from '../model/copy';

const CreativesHeader: FC = () => {
  const [isPendingCopyCreatives, copyCreatives] = useUnit([
    $isPendingCopyCreatives,
    $copyCreatives,
  ]);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const val = useUnit($searchFilter);
  return (
    <Header>
      <Typography variant="h1">Креативы</Typography>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Search
          placeholder="Название / UUID / ORD ID"
          value={val}
          inputProps={{ maxLength: 80 }}
          onChange={(e) => onSetSearchFilter(e.target.value)}
          onClose={() => onSetSearchFilter('')}
        />
        <Import handleClick={() => setOpen(true)} />
        <OtherFilters />
        <Button
          component={NavLink}
          to={addressesORD.creativeCreatePath}
          variant="contained"
          sx={{
            marginLeft: '20px',
          }}
        >
          Добавить
        </Button>

        <Button
          onClick={() => onCopyCreatives()}
          variant="contained"
          sx={{
            marginLeft: '20px',
          }}
          disabled={copyCreatives.length < 1 || isPendingCopyCreatives}
        >
          Копировать выбранные{' '}
          {copyCreatives.length ? `(${copyCreatives.length})` : ''}
        </Button>
        <Button
          onClick={() => resetSelectedCreatives()}
          variant="contained"
          sx={{
            marginLeft: '20px',
          }}
          disabled={isPendingCopyCreatives || copyCreatives.length < 1}
        >
          Очистить выбранные
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Импорт</DialogTitle>
        <DialogContent>
          <Box
            display="grid"
            alignItems="center"
            gridTemplateColumns="200px 200px"
            gap="0 20px"
          >
            <SelectField label="Статус" options={[]} />
            <SelectField label="Статус" options={[]} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box />
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            sx={{
              maxWidth: '100px',
              marginLeft: 'auto',
            }}
          >
            Импорт
          </Button>
        </DialogActions>
        <Box className="MuiDialogClose" onClick={() => setOpen(false)}>
          <FiX color={theme.palette.primary.main} size={24} />
        </Box>
      </Dialog>
    </Header>
  );
};

export default CreativesHeader;
