import request from '../request';

export type Status = 'ACTIVE' | 'DELETED' | 'ON_APPROVAL';
export type ErirStatus =
  | 'NONE'
  | 'PENDING'
  | 'SUCCESS'
  | 'ERROR'
  | 'PENDING_RESOURCE';

export type Category = 'Initial' | 'Final' | 'Outer' | 'SelfPromotion';
export type Filter = {
  limit?: number;
  offset?: number;
  ord?: number;
  customer?: string;
  executor?: string;
  status?: Status;
  erirStatus?: ErirStatus;
  search?: string;
  customerInn?: string;
  executorInn?: string;
  isInvoiceContract?: boolean;
  category?: Category;
};
export type PContractGetList = {
  filter?: Filter;
};

type Type = 'Intermediary' | 'Original' | 'Additional' | 'SelfPromotion';
type ActionType = 'Distribution' | 'Conclude' | 'Commercial' | 'Other' | 'None';
type SubjectType =
  | 'Representation'
  | 'DistributionOrganization'
  | 'Mediation'
  | 'Distribution'
  | 'Other';

export type ContractItem = {
  uuid: string;
  customer: string;
  executor: string;
  type: Type;
  executorIsObligedForRegistration: boolean;
  actionType: ActionType;
  subjectType: SubjectType;
  number: string;
  date: number;
  amount?: number;
  erirExportedOn?: number;
  erirPlannedExportDate?: number;
  erirStatus?: ErirStatus;
  ord: number;
  customerInn?: string;
  executorInn?: string;
  externalId?: string;
  parent?: string;
  parentExternalId?: string;
  api?: boolean;
  status: Status;
  isInvoiceContract: boolean;
  category: Category;
  agentActingForPublisher: boolean;
  finalContract?: string;
  useNetworkStat: boolean;
};
export type RContractGetList = { total: number; rows: ContractItem[] };
export const contractGetList = request<
  RContractGetList,
  PContractGetList | undefined
>({
  method: 'contract.getList',
});

export type RContractCreate = ContractItem;
export type PContractCreate = {
  fields: {
    customer: string;
    executor: string;
    type: Type;
    executorIsObligedForRegistration: boolean;
    actionType: ActionType;
    subjectType: SubjectType;
    number?: string;
    date: string;
    amount?: number;
    erirExportedOn?: number;
    erirPlannedExportDate?: number;
    erirStatus?: ErirStatus;

    ord: number;
    externalId?: string;
    isInvoiceContract?: boolean;

    category: Category;
    agentActingForPublisher: boolean;
    finalContract?: string;
    parent?: string;
    useNetworkStat: boolean;
  };
};
export const contractCreate = request<RContractCreate, PContractCreate>({
  method: 'contract.create',
});

export type PContractGet = {
  uuid: string;
};
export type RContractGet = ContractItem;
export const contractGet = request<RContractGet, PContractGet>({
  method: 'contract.get',
});

export type FildsUpdate = {
  type?: Type;
  executorIsObligedForRegistration?: boolean;
  actionType?: ActionType;
  subjectType?: SubjectType;
  number?: string;
  date?: string;
  amount?: number;
  isVat?: boolean;
  status?: Status;
  isInvoiceContract?: boolean;
  agentActingForPublisher: boolean;
  finalContract?: string | null;
  parent?: string | null;
  useNetworkStat: boolean;
};
export type PContractUpdate = {
  uuid: string;
  fields: FildsUpdate;
};
export type RContractUpdate = ContractItem;
export const contractUpdate = request<RContractUpdate, PContractUpdate>({
  method: 'contract.update',
});

export type PContractSendErir = {
  uuid: string;
};
export const contractSendErir = request<null, PContractSendErir>({
  method: 'contract.sendErir',
});
