import { combine, createEvent, createStore, restore, sample } from 'effector';
import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import {
  organizationGetList,
  ROrganizationGetList,
} from '../../../../apiRPC/organization';
import { $ordList, ordGetListFX } from '../../../model/ord';
import { Status } from '../../../../apiRPC/contracts';

export const organizationGetListFX = errorHandlerFx(
  createEffectJWT(organizationGetList),
);

export const onOrganizationGetList = createEvent();

export const onSetStatusFilter = createEvent<Status | null>();

export const resetStatusFilter = createEvent();
export const $statusFilter = restore(onSetStatusFilter, 'ACTIVE').reset(
  resetStatusFilter,
);

export const organizationListReset = createEvent();
export const $organizationList = createStore<
  ROrganizationGetList['rows'] | null
>(null)
  .on(organizationGetListFX.doneData, (_, payload) => payload.rows)
  .reset(organizationListReset);

export const onOrganizationsFilter = createEvent<string>();
export const onOrganizationsFilterReset = createEvent();
export const $organizationsFilter = restore(onOrganizationsFilter, '').reset(
  onOrganizationsFilterReset,
);

export const $organizationListFilter = combine(
  [$organizationList, $organizationsFilter],
  ([organizationList, organizationsFilter]) => {
    if (!organizationsFilter) return organizationList;
    if (!organizationList) return null;
    return organizationList.filter(
      (item) =>
        item.name.toUpperCase().includes(organizationsFilter.toUpperCase()) ||
        item.aliases
          ?.toUpperCase()
          .includes(organizationsFilter.toUpperCase()) ||
        item.number.toUpperCase().includes(organizationsFilter.toUpperCase()) ||
        item.inn?.includes(organizationsFilter),
    );
  },
);

export const restFieldType = createEvent();
export const $fieldType = createStore<
  'loading' | 'ErrorEmpty' | 'ErrorNoContent' | 'Success'
>('loading')
  .on(organizationGetListFX.fail, () => 'ErrorNoContent')
  .on(organizationGetListFX.pending, (_, payload) =>
    payload ? 'loading' : undefined,
  )
  .on(organizationGetListFX.done, () => 'Success')
  .on($organizationListFilter, (_, payload) =>
    payload?.length ? 'Success' : 'ErrorEmpty',
  )
  .reset(restFieldType);

sample({
  clock: onOrganizationGetList,
  source: $statusFilter,
  fn: (status) => ({ filter: { status: status ?? undefined } }),
  target: organizationGetListFX,
});

sample({
  clock: $statusFilter,
  target: onOrganizationGetList,
});

sample({
  clock: organizationGetListFX,
  source: $ordList,
  filter: (state) => state.length === 0,
  fn: () => undefined,
  target: ordGetListFX,
});
export const resetOrganizations = () => {
  organizationListReset();
  onOrganizationsFilterReset();
  restFieldType();
  resetStatusFilter();
};
