import React, { FC } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  ListItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import ReactRouterPrompt from 'react-router-prompt';
import * as Yup from 'yup';
import { LabelForContracts } from '../../../../Campaigns/UI/LabelForContracts';

import {
  onLoadListsCampaign,
  resetCatalogsCampaign,
  searchCampaign,
} from '../model';
import { ContractAutocomplete } from '../../../../../components/ContractAutocomplete';
import { SelectMS } from '../../../../../../UI/form/SelectMS';
import { dataForm, dataTypes } from '../../../options';
import { CodeMirrorInput } from '../../../../../../UI/CodeMirrorInput';
import Modal from '../../../../../../components/Modal';
import { useUpdate } from './useUpdate';
import { categories } from '../../kktuCodes';
import { getValCategories } from '../../helpers';

export const Form: FC = () => {
  const {
    formik,
    handleChange,
    campaignList,
    currentCampaign,
    onBlurByTempUrl,
    onPressEnterByTempUrl,
    onDeleteItemUrl,
    isPrompt,
    refValueShop,
    valValueShop,
    hideUrl,
    creative,
  } = useUpdate();

  return (
    <Box mt="20px">
      <Paper
        elevation={0}
        sx={{
          padding: '30px',
        }}
      >
        <Typography fontWeight={700} mb={3.8}>
          Параметры
        </Typography>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap="30px" mb={1}>
          <TextField
            label="Название"
            required
            variant="standard"
            name="name"
            inputProps={{ maxLength: 255 }}
            autoComplete="none"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={handleChange}
            error={Boolean(formik.errors.name)}
            helperText={formik.errors.name}
          />

          <SelectMS
            error={Boolean(formik.errors.type)}
            required
            label="Тип"
            value={formik.values.type || ''}
            name="type"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={dataTypes}
            helperText={formik.errors.type}
          />

          <SelectMS
            error={Boolean(formik.errors.form)}
            required
            label="Форма распространения рекламы"
            value={formik.values.form || ''}
            name="form"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={dataForm}
            helperText={formik.errors.form}
          />

          <Box
            sx={{
              position: 'relative',
            }}
          >
            <ContractAutocomplete
              options={campaignList}
              value={formik.values.campaign ?? null}
              sx={{
                '.MuiInputLabel-root': { zIndex: 99 },
              }}
              label="Кампания"
              disabled={!currentCampaign}
              required
              placeholder="Кампания"
              onInputChange={(event, value, reason) => {
                if (reason === 'clear') {
                  formik.setFieldValue('campaign', undefined);
                  resetCatalogsCampaign();
                }
              }}
              onChange={(_, v) => {
                formik.setFieldValue('campaign', v).then(() => {
                  formik.validateField('campaign');
                });
              }}
              isOptionEqualToValue={(values, opt) => values?.uuid === opt?.uuid}
              onScrollEnd={onLoadListsCampaign}
              search={searchCampaign}
              getOptionLabel={(option) => `${option?.name} `}
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  key={option?.uuid}
                  component="li"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {option?.name}
                </ListItem>
              )}
              error={currentCampaign ? Boolean(formik.errors.campaign) : false}
              helperText={currentCampaign ? formik.errors.campaign : undefined}
            />
          </Box>

          <TextField
            variant="standard"
            value={formik.values.creativeOkveds ?? ''}
            name="creativeOkveds"
            inputProps={{ maxLength: 255 }}
            autoComplete="none"
            onBlur={formik.handleBlur}
            onChange={handleChange}
            error={Boolean(formik.errors.creativeOkveds)}
            helperText={formik.errors.creativeOkveds}
            sx={{ '.MuiInputLabel-root': { zIndex: 99 } }}
            label={
              <LabelForContracts text="Коды ОКВЭД" title="Через запятую" />
            }
          />

          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Autocomplete
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={categories}
              value={
                getValCategories(formik.values.kktuCodes, categories) ?? null
              }
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (Array.isArray(option)) {
                  return option[0];
                }
                return `${option.value} ${option?.label}`;
              }}
              onChange={(_, v) => {
                const value =
                  Array.isArray(v) || typeof v === 'string' ? v : v?.value;
                formik.setFieldValue('kktuCodes', value).then(() => {
                  formik.validateField('kktuCodes');
                });
              }}
              onKeyDownCapture={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              filterOptions={(options, params) => {
                const filtered = options.filter((option) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(params.inputValue.toLowerCase())
                  )
                    return true;
                  if (
                    option.value
                      .toLowerCase()
                      .includes(params.inputValue.toLowerCase())
                  )
                    return true;
                  return false;
                });

                const { inputValue } = params;

                const isValid = Yup.string()
                  .matches(/^(\d{1,2}\.\d{1,2}\.\d{1,2})$/)
                  .isValidSync(inputValue);

                if (!isValid) return filtered;

                const isExisting = options.some(
                  (option) =>
                    inputValue ===
                    (typeof option === 'string' ? option : option.value),
                );

                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    value: inputValue,
                    label: '',
                  });
                }

                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Код ККТУ"
                  variant="standard"
                  error={Boolean(formik.errors.kktuCodes)}
                  helperText={formik.errors.kktuCodes}
                />
              )}
            />
          </Box>

          <TextField
            label="Параметры целевой аудитории рекламы"
            variant="standard"
            name="targetAudienceDescription"
            autoComplete="none"
            onBlur={formik.handleBlur}
            onChange={handleChange}
            error={Boolean(formik.errors.targetAudienceDescription)}
            helperText={formik.errors.targetAudienceDescription}
            value={formik.values.targetAudienceDescription}
          />

          <TextField
            label="Идентификатор магазина"
            variant="standard"
            name="shop"
            autoComplete="none"
            value={valValueShop}
            inputRef={refValueShop}
            error={Boolean(formik.errors.shop)}
            helperText={formik.errors.shop}
          />
        </Box>
        <Box display="grid" gridTemplateColumns="1fr" gap={1}>
          {creative?.ord === 4 && (
            <>
              <div />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isNotUrl}
                    onChange={(e) => {
                      formik
                        .setFieldValue('isNotUrl', e.target.checked)
                        .then(() => {
                          formik.validateField('url_temp');
                        });
                    }}
                    name="isNotUrl"
                  />
                }
                label="НЕ указывать целевую ссылку"
              />
            </>
          )}

          {!hideUrl && (
            <>
              <TextField
                label="Целевая ссылка"
                variant="standard"
                name="url_temp"
                autoComplete="none"
                onChange={handleChange}
                onBlur={onBlurByTempUrl}
                onKeyPress={onPressEnterByTempUrl}
                error={Boolean(formik.errors.url_temp)}
                helperText={formik.errors.url_temp}
                value={formik.values.url_temp}
              />

              <Box display="flex" flexWrap="wrap" gap="10px">
                {formik.values.url?.map((item, index) => (
                  <Chip
                    title={item}
                    key={item + index}
                    label={item}
                    variant="filled"
                    color="primary"
                    onDelete={onDeleteItemUrl(index)}
                  />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          mt: '20px',
          padding: '30px',
        }}
      >
        <Typography fontWeight={700} mb={3.8}>
          Параметры ресурсов
        </Typography>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap="30px" mb={4}>
          <TextField
            label="Общее описание объекта рекламирования"
            placeholder="Общее описание объекта рекламирования"
            multiline
            required
            rows={5}
            value={formik.values.description}
            name="description"
            onBlur={formik.handleBlur}
            onChange={handleChange}
            error={Boolean(formik.errors.description)}
            helperText={formik.errors.description}
            inputProps={{ maxLength: 2000 }}
          />
          <TextField
            label={
              <LabelForContracts
                text="Описание для ресурса по умолчанию"
                title="Только для ссылок"
              />
            }
            placeholder="Описание для ресурса по умолчанию"
            multiline
            rows={5}
            value={formik.values.defaultResourceDescription}
            name="defaultResourceDescription"
            inputProps={{ maxLength: 1000 }}
            onBlur={formik.handleBlur}
            onChange={handleChange}
            error={Boolean(formik.errors.defaultResourceDescription)}
            helperText={formik.errors.defaultResourceDescription}
          />
        </Box>
      </Paper>
      <ReactRouterPrompt when={isPrompt}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            open={isActive}
            handleClose={onCancel}
            onConfirm={onConfirm}
            title="Подтвердите переход"
            outlinedText="Остаться на этой странице"
            containedText="Покинуть эту страницу"
          >
            Вы действительно хотите покинуть страницу?
          </Modal>
        )}
      </ReactRouterPrompt>
    </Box>
  );
};
