import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { Button } from '@mui/material';
import { Category, ErirStatus } from '../../../../../apiRPC/contracts';
import { FilterWrapper } from '../../../../../components/Filter';
import SelectField from '../../../../../UI/form/SelectField';
import { $ordOption, $organizationOption } from '../../model/catalogs';
import {
  $countOtherFilter,
  $customerInnFilter,
  $erirStatusFilter,
  $executorInnFilter,
  $CategoryFilter,
  $ordFilter,
  onMapOtherFilters,
  onResetOtherFilter,
  onSendOtherFilter,
  onSetCustomerInnFilter,
  onSetErirStatusFilter,
  onSetExecutorInnFilter,
  onSetCategoryFilter,
  onSetOrdFilter,
  $statusFilter,
  onSetStatusFilter,
} from '../model/filtres/otherFilters';
import { contractGetListFX } from '../model';
import { filterOptionsOrganizations } from '../../helper/filterOptionsOrganizations';
import { optionsStatus } from '../../../Organization/options';

export const dataErirStatus: { label: string; value: ErirStatus }[] = [
  {
    label: 'Не отправляется',
    value: 'NONE',
  },
  {
    label: 'В ожидании',
    value: 'PENDING',
  },
  {
    label: 'Ожидает выгрузки ресурсов',
    value: 'PENDING_RESOURCE',
  },
  {
    label: 'Успешно',
    value: 'SUCCESS',
  },
  {
    label: 'Ошибка',
    value: 'ERROR',
  },
];

const optionsCategory: {
  label: string;
  value: Category;
}[] = [
  {
    label: 'Изначальный договор',
    value: 'Initial',
  },
  {
    label: 'Доходный договор',
    value: 'Final',
  },
  {
    label: 'Расходный договор',
    value: 'Outer',
  },
];

export const OtherFilters: FC = () => {
  const organizationOption = useUnit($organizationOption);
  const ordOption = useUnit($ordOption);

  const statusFilter = useUnit($statusFilter);
  const statusValue = optionsStatus.find((item) => item.value === statusFilter);

  const erirStatusFilter = useUnit($erirStatusFilter);
  const erirStatusValue = dataErirStatus.find(
    (item) => item.value === erirStatusFilter,
  );

  const categoryFilter = useUnit($CategoryFilter);
  const categoryValue = optionsCategory.find(
    (item) => item.value === categoryFilter,
  );

  const customerInnFilter = useUnit($customerInnFilter);
  const customerInnValue = organizationOption.find(
    (item) => item.value === customerInnFilter,
  );

  const executorInnFilter = useUnit($executorInnFilter);
  const executorInnValue = organizationOption.find(
    (item) => item.value === executorInnFilter,
  );

  const ordFilter = useUnit($ordFilter);
  const ordFilterValue = ordOption.find((item) => item.value === ordFilter);

  const countOtherFilter = useUnit($countOtherFilter);

  const pending = useUnit(contractGetListFX.pending);
  const disableSendBtn = pending;
  return (
    <FilterWrapper
      count={countOtherFilter}
      onOpen={onMapOtherFilters}
      id="OtherFilters"
      actions={
        <>
          <Button variant="text" onClick={() => onResetOtherFilter()}>
            Сбросить фильтр
          </Button>
          <Button
            disabled={disableSendBtn}
            variant="contained"
            onClick={() => onSendOtherFilter()}
          >
            Применить
          </Button>
        </>
      }
    >
      <SelectField
        label="Статус ЕРИР"
        options={dataErirStatus}
        value={erirStatusValue || null}
        onChange={(_, v) => {
          onSetErirStatusFilter(v?.value ?? null);
        }}
      />
      <SelectField
        label="Категория договора"
        options={optionsCategory}
        value={categoryValue || null}
        onChange={(_, v) => {
          onSetCategoryFilter(v?.value ?? null);
        }}
      />
      <SelectField
        label="Заказчик"
        placeholder="Введите название или ИНН"
        options={organizationOption || []}
        filterOptions={filterOptionsOrganizations}
        value={customerInnValue || null}
        onChange={(_, v) => {
          onSetCustomerInnFilter(v?.value ?? null);
        }}
      />
      <SelectField
        label="Исполнитель"
        placeholder="Введите название или ИНН"
        options={organizationOption || []}
        filterOptions={filterOptionsOrganizations}
        value={executorInnValue || null}
        onChange={(_, v) => {
          onSetExecutorInnFilter(v?.value ?? null);
        }}
      />
      <SelectField
        label="ОРД"
        options={ordOption}
        value={ordFilterValue || null}
        onChange={(_, v) => {
          onSetOrdFilter(v?.value ?? null);
        }}
      />
    </FilterWrapper>
  );
};
