import { Box, List, ListItem, ListItemButton, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { FC } from 'react';
import { FiBriefcase, FiFileText } from 'react-icons/fi';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ReactComponent as CampaignIconSvg } from '../../../assets/icons/campaignIcon.svg';
import { ReactComponent as CreativeIconSvg } from '../../../assets/icons/creativeIcon.svg';
import css from '../styles.module.scss';
import { addressesORD } from '../../../ORD/addresses';

export const ORD: FC = () => {
  const location = useLocation();
  return (
    <>
      <Divider />
      <List
        sx={{
          paddingTop: '18px',
        }}
      >
        <ListItem disablePadding>
          <ListItemButton
            selected={
              decodeURI(location.pathname).toUpperCase() ===
                '/Organizations'.toUpperCase() ||
              decodeURI(location.pathname).toUpperCase() ===
                addressesORD.ORDPath.toUpperCase() ||
              decodeURI(location.pathname).toUpperCase() ===
                addressesORD.organizationsPath.toUpperCase()
            }
            component={RouterLink}
            to={addressesORD.organizationsPath}
          >
            <Box className={css.ListItemButton}>
              <FiBriefcase size={24} />
              <Typography>Организации</Typography>
            </Box>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to={addressesORD.contractsPath}
            selected={
              location.pathname.toUpperCase() ===
              addressesORD.contractsPath.toUpperCase()
            }
          >
            <Box className={css.ListItemButton}>
              <FiFileText size={24} />
              <Typography>Договоры</Typography>
            </Box>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to={addressesORD.campaignsPath}
            selected={
              location.pathname.toUpperCase() ===
              addressesORD.campaignsPath.toUpperCase()
            }
          >
            <Box className={css.ListItemButton}>
              <CampaignIconSvg />
              <Typography>Кампании</Typography>
            </Box>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to={addressesORD.creativesPath}
            selected={
              location.pathname.toUpperCase() ===
              addressesORD.creativesPath.toUpperCase()
            }
          >
            <Box className={css.ListItemButton}>
              <CreativeIconSvg />
              <Typography>Креативы</Typography>
            </Box>
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};
