import { Box, Paper, TextField, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormikProps, useFormik } from 'formik';
import { LabelForContracts } from '../../../../Campaigns/UI/LabelForContracts';
import { FormCreateCreative } from '../types';
import type { FieldsCreateCreative } from '../index';

type Props = {
  formik: FormikProps<FieldsCreateCreative>;
  handleChange: ReturnType<
    typeof useFormik<FormCreateCreative>
  >['handleChange'];
};

const ParamResourse: FC<Props> = ({ formik, handleChange }) => (
  <Paper
    elevation={0}
    sx={{
      padding: '30px',
    }}
  >
    <Typography fontWeight={700} mb={3.8}>
      Параметры ресурсов
    </Typography>
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap="30px" mb={4}>
      <TextField
        label="Общее описание объекта рекламирования"
        placeholder="Общее описание объекта рекламирования"
        multiline
        required
        rows={5}
        value={formik.values.description}
        name="description"
        onBlur={formik.handleBlur}
        onChange={handleChange}
        error={Boolean(formik.errors.description)}
        helperText={formik.errors.description}
        inputProps={{ maxLength: 2000 }}
      />
      <TextField
        label={
          <LabelForContracts
            text="Описание для ресурса по умолчанию"
            title="Только для ссылок"
          />
        }
        placeholder="Описание для ресурса по умолчанию"
        multiline
        rows={5}
        value={formik.values.defaultResourceDescription}
        name="defaultResourceDescription"
        onBlur={formik.handleBlur}
        onChange={handleChange}
        error={Boolean(formik.errors.defaultResourceDescription)}
        helperText={formik.errors.defaultResourceDescription}
        inputProps={{ maxLength: 1000 }}
      />
    </Box>
  </Paper>
);

export default ParamResourse;
