import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import ReactRouterPrompt from 'react-router-prompt';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useStoreMap, useUnit } from 'effector-react';
import { number } from 'yup';
import Modal from '../../../../components/Modal';
import Header from '../../../components/Header';
import {
  $ordList,
  $platformList,
  onOrdGetList,
  onPlatformGetList,
} from '../../../model/ord';
import { organizationCreateFX } from './model';
import {
  POrganizationCreate,
  TypeOrganization,
} from '../../../../apiRPC/organization';
import { navigateForEffect } from '../../../../model/routing';
import { errorsMessage } from '../../../../apiRPC/errors';
import { addressesORD } from '../../../addresses';
import { okms } from '../oksm';
import { isErrorProps } from '../../../../apiRPC/request';
import { mapName } from '../options';

type Form = Omit<POrganizationCreate['fields'], 'ord'> & {
  ord: number | '';
};
function isEmptyValue(
  obj: Record<string, string | number | boolean | Array<unknown>>,
): boolean {
  let res = true;
  for (const key in obj) {
    if (obj[key]) {
      res = false;
      break;
    }
  }
  return res;
}

const types = [
  ['ul', 'Юридическое лицо'],
  ['fl', 'Физическое лицо'],
  ['ip', 'Индивидуальный предприниматель'],
  ['ffl', 'Иностранное физическое лицо'],
  ['ful', 'Иностранное юридическое лицо'],
];

const typesForFL = ['fl', 'ip', 'ffl'];
const foreignL = ['ffl', 'ful'];

const validPair: [string, string][] = [
  ['mobilePhone', 'epayNumber'],
  ['regNumber', 'alternativeInn'],
];

type KeysFields = keyof Form;
function removeEmptyFields(
  obj: Form,
  keysToRemove: KeysFields[],
): POrganizationCreate['fields'] {
  const newObj = {} as POrganizationCreate['fields'];
  const keys = Object.keys(obj) as KeysFields[];
  keys.forEach((key) => {
    if (!keysToRemove.includes(key) || obj[key] !== '') {
      // @ts-ignore
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

const styleMuiFormHelperText = { '.MuiFormHelperText-root': { mb: '-23px' } };
const styleDisabled = { '.Mui-disabled': { cursor: 'not-allowed' } };
const oksmRu = '643';

export const Create: FC = () => {
  const [disableModal, setDisableModal] = useState(false);
  const formik = useFormik<Form>({
    initialValues: {
      name: '',
      aliases: '',
      exportName: '',
      inn: '',
      type: '' as TypeOrganization,
      ord: 4,
      mobilePhone: '',
      epayNumber: '',
      regNumber: '',
      alternativeInn: '',
      oksmNumber: '',
      directClient: false,
      isRr: false,
      isOrs: false,
      rsUrl: '',
      isRd: false,
      isRa: false,
      platforms: [],
    },
    validationSchema: Yup.object().shape(
      {
        name: Yup.string()
          .required('Обязательное для заполнения поле')
          .trim()
          .min(1, 'Минимум 1 знак')
          .max(255, 'Максимум 255 знаков'),
        aliases: Yup.string().max(1000, 'Максимум 1000 знаков'),
        exportName: Yup.string(),
        type: Yup.string().required('Обязательное для заполнения поле'),
        inn: Yup.string().when('type', {
          is: (val: string) => ['ul', 'fl', 'ip'].includes(val),
          then: (schema) =>
            schema
              .required('Обязательное для заполнения поле')
              .matches(/^\d+$/, 'Поле должно содержать только цифры')
              .min(6, 'Минимум 6 знаков')
              .max(40, 'Максимум 40 знаков'),
          otherwise: (schema) => schema.notRequired(),
        }),

        mobilePhone: Yup.string().when('type', {
          is: (type: string) => type === 'ffl',
          then: (schema) =>
            schema.when('epayNumber', {
              is: (epayNumber: string) => !epayNumber,
              then: (schemaE) =>
                schemaE
                  .required('Заполните это поле или EPAY')
                  .matches(/^\d+$/, 'Поле должно содержать только цифры'),
              otherwise: (schemaOE) => schemaOE.notRequired(),
            }),
          otherwise: (schema) => schema.notRequired(),
        }),

        epayNumber: Yup.string().when('type', {
          is: (type: string) => type === 'ffl',
          then: (schema) =>
            schema.when('mobilePhone', {
              is: (mobilePhone: string) => !mobilePhone,
              then: (schemaM) =>
                schemaM
                  .required(
                    'Заполните это поле или Абонентский номер мобильного телефона',
                  )
                  .matches(
                    /^[a-zA-Z0-9]*$/,
                    'Поле должно содержать только цифры и латинские буквы',
                  ),
              otherwise: (schemaM) => schemaM.notRequired(),
            }),

          otherwise: (schema) => schema.notRequired(),
        }),

        regNumber: Yup.string().when('type', {
          is: (type: string) => type === 'ful',
          then: (schema) =>
            schema.when(['alternativeInn', 'ord'], {
              is: (alternativeInn: string, ord: number) =>
                ord === 4 || !alternativeInn,
              then: (schemaA) =>
                schemaA
                  .when('ord', {
                    is: (ord: number) => ord === 4,
                    then: (schemab) => schemab.required('Заполните это поле '),
                    otherwise: (schemaD) =>
                      schemaD.required(
                        'Заполните это поле или "Номер налогоплательщика либо его аналог" ',
                      ),
                  })
                  .matches(
                    /^[a-zA-Z0-9]*$/,
                    'Поле должно содержать только цифры и латинские буквы',
                  ),
              otherwise: (schemaA) => schemaA.notRequired(),
            }),
          otherwise: (schema) => schema.notRequired(),
        }),

        alternativeInn: Yup.string().when('type', {
          is: (type: string) => type === 'ful',
          otherwise: (schema) => schema.notRequired(),
          then: (schema) =>
            schema.when(['regNumber', 'ord'], {
              is: (regNumber: string, ord: number) => !(regNumber || ord === 4),
              then: (schemaR) =>
                schemaR
                  .required('Заполните это поле или Регистрационный номер')
                  .matches(
                    /^[a-zA-Z0-9]*$/,
                    'Поле должно содержать только цифры и латинские буквы',
                  ),
            }),
        }),
        ord: Yup.number()
          .required('Обязательное для заполнения поле')
          .when(['isOrs', 'isRr', 'isRd', 'isRa'], {
            is: (...values: boolean[]) => {
              const isCheked = values.includes(true);
              return !isCheked;
            },
            then: (schema) =>
              schema.test({
                name: 'is-name',
                message: () =>
                  'Должен быть выбран хотя бы один тип контрагента',
                test: (value) => value !== 5,
              }),

            otherwise: (schema) => schema.notRequired(),
          }),

        oksmNumber: Yup.string(),
        directClient: Yup.boolean(),
        isRr: Yup.boolean(),
        isOrs: Yup.boolean(),
        rsUrl: Yup.string()
          .url('Неверный формат')
          .when(['isOrs'], {
            is: (isOrs: boolean) => isOrs,
            then: (schema) =>
              schema.required('Обязательное для заполнения поле'),
            otherwise: (schema) => schema.notRequired(),
          }),
        isRd: Yup.boolean(),
        isRa: Yup.boolean(),
        platforms: Yup.array()
          .of(Yup.string())
          .when(['isRr', 'isOrs', 'ord'], {
            is: (isRr: boolean, isOrs: boolean, ord: number) =>
              ord === 4 && (isRr || isOrs),
            then: (schema) => schema.min(1, 'Выберите хотя бы один элемент'),
            otherwise: (schema) => schema.notRequired(),
          }),
      },
      validPair,
    ),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values, f) => {
      setDisableModal(true);

      const data = removeEmptyFields(values, [
        'inn',
        'alternativeInn',
        'regNumber',
        'epayNumber',
        'oksmNumber',
      ]);
      const isOrdForType = [4, 5].includes(data.ord);
      if (data.ord !== 3) delete data.directClient;
      if (!isOrdForType) {
        delete data.isRr;
        delete data.isOrs;
        delete data.isRd;
        delete data.isRa;
      }

      if (data.ord !== 4) delete data.platforms;
      if (data.ord !== 3) delete data.directClient;
      if (!data.isOrs) delete data.rsUrl;
      if (!foreignL.includes(formik.values.type)) delete data.oksmNumber;
      organizationCreateFX({ fields: data })
        .then(() => {
          f.resetForm();

          toast.success('Организация успешно создана ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          navigateForEffect(addressesORD.organizationsPath);
        })
        .catch((e) => {
          setDisableModal(false);

          if (e.code === 1228) {
            f.setFieldError(
              'inn',
              'Организация с указанным ИНН уже существует',
            );
          }
          if (e.code === 1229) {
            f.setFieldError('mobilePhone', errorsMessage[1229]);
          }

          if (e.code === 1230) {
            f.setFieldError('epayNumber', errorsMessage[1230]);
          }
          if (e.code === 1231) {
            f.setFieldError('regNumber', errorsMessage[1231]);
          }
          if (e.code === 1232) {
            f.setFieldError('alternativeInn', errorsMessage[1232]);
          }
          if (e.code === 99) {
            f.setFieldError('platforms', e.description);
          }
          let isErr = false;
          if (isErrorProps(e)) {
            e?.data?.fields?.forEach((field) => {
              let text = field.description;
              Array.from(mapName).forEach(([key, val]) => {
                text = text.replace(key, val);
              });
              f.setFieldError(field.field, text);
              isErr = true;
            });
          }
          if (!isErr) {
            toast.error(e.description ?? e.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        });
    },
  });

  const platformOption = useStoreMap({
    store: $platformList,
    keys: [formik.values.ord],
    fn: (state, [ord]) =>
      state
        ?.filter((item) => item.ord === ord)
        ?.map((item) => ({
          label: item.name,
          value: item.uuid,
        })),
    defaultValue: [],
  });

  const isChangeExportName = useRef<boolean>(false);

  const handleChange: (typeof formik)['handleChange'] = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      if (name === 'exportName') {
        isChangeExportName.current = true;
      }

      let value: string | number | boolean | string[] = e.target.value;
      if (name === 'directClient') {
        value = Boolean(e.target.value);
      }
      formik.setFieldValue(name, value).then(async () => {
        formik.validateField(name);
        const typesForRu = ['ul', 'fl', 'ip'];

        if (name === 'ord') {
          await Promise.all([
            formik.setFieldValue('isRr', false),
            formik.setFieldValue('isOrs', false),
            formik.setFieldValue('rsUrl', ''),
            formik.setFieldValue('platforms', []),
            formik.validateField('rsUrl'),
            formik.validateField('alternativeInn'),
            value !== 4 ? formik.setFieldValue('alternativeInn', '') : null,
            name === 'ord' &&
            +value === 3 &&
            !typesForRu.includes(formik.values.type)
              ? formik.setFieldValue('type', '')
              : null,
          ]);
        }

        if (name === 'type') {
          validPair.forEach((item) =>
            item.forEach(async (field) => {
              await Promise.all([
                formik.setFieldValue(field, ''),
                formik.validateField(field),
              ]);
            }),
          );
          await formik.setFieldValue('inn', '');
          await formik.validateField('inn');

          if (!typesForRu.includes(value as TypeOrganization)) {
            await formik.setFieldValue('oksmNumber', '');
            await formik.validateField('oksmNumber');
          }
          if (
            typesForRu.includes(value as TypeOrganization) &&
            formik.values.oksmNumber
          ) {
            await formik.setFieldValue('oksmNumber', '643');
            await formik.validateField('oksmNumber');
          }
        }

        if (
          name === 'oksmNumber' &&
          ((value === oksmRu && !typesForRu.includes(formik.values.type)) ||
            (value !== oksmRu && typesForRu.includes(formik.values.type)))
        ) {
          await formik.setFieldValue('type', '');
          await formik.validateField('type');
        }
        if (name === 'platforms' && Array.isArray(value)) {
          await Promise.all([
            await formik.setFieldValue('platforms', value),
            await formik.validateField('platforms'),
          ]);
        }

        const validPairFlat = validPair.flat();
        if (validPairFlat.includes(name))
          validPairFlat.forEach(formik.validateField);
      });
    },
    [formik],
  );

  const handleChecked: (typeof formik)['handleChange'] = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const isRrOrOrs = ['isRr', 'isOrs'].includes(name);

      const mapLastValues: Record<string, boolean> = {
        isRr: Boolean(formik.values.isRr),
        isOrs: Boolean(formik.values.isOrs),
      };
      const val = e.target.checked;
      mapLastValues[name] = val;

      const lastValuesHasCheck = Object.values(mapLastValues).includes(true);

      const isClearPlatform =
        isRrOrOrs &&
        !val &&
        formik.values.platforms?.length &&
        !lastValuesHasCheck;
      const isClearRsUrl = name === 'isOrs' && !val && formik.values.rsUrl;

      formik.handleChange(e);
      if (isClearPlatform) {
        formik.setFieldValue('platforms', []);
      }
      if (isClearRsUrl) {
        formik.setFieldValue('rsUrl', '');
      }
    },
    [formik],
  );

  const onBlurName = () => {
    if (formik.values.exportName) return;
    if (!formik.values.name) return;
    formik.setFieldValue('exportName', formik.values.name);
  };

  const [ordList] = useUnit([$ordList]);
  useEffect(() => {
    onOrdGetList();
    onPlatformGetList();
  }, []);

  const pending = useUnit(organizationCreateFX.pending);
  const isPrompt = disableModal ? false : !isEmptyValue(formik.values);

  const showRsUrl = formik.values.isOrs;
  const showPlatform =
    (formik.values.isOrs || formik.values.isRr) && formik.values.ord === 4;

  const disableSubmit = pending || !formik.isValid;
  return (
    <Box>
      <Header>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h1"
            sx={{
              marginLeft: '2px',
            }}
          >
            Новая организация
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <NavLink to={addressesORD.organizationsPath}>
            <Button
              variant="outlined"
              sx={{
                marginLeft: '36px',
              }}
              disabled={pending}
            >
              Отмена
            </Button>
          </NavLink>
          <LoadingButton
            onClick={formik.submitForm}
            loading={pending}
            variant="contained"
            sx={{
              marginLeft: '36px',
            }}
            disabled={disableSubmit}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </Header>
      <Box display="grid" gridTemplateColumns="auto 370px" gap="0 40px">
        <Paper
          elevation={0}
          sx={{
            padding: '30px',
          }}
        >
          <Typography fontWeight={700} mb={3.8}>
            Параметры
          </Typography>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0 30px" mb={4}>
            <FormControl variant="standard" error={Boolean(formik.errors.type)}>
              <InputLabel id="type-label" required>
                Тип
              </InputLabel>
              <Select
                labelId="type-label"
                name="type"
                value={formik.values.type}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                label="Тип*"
              >
                {types.map(([key, value]) => (
                  <MenuItem
                    key={key}
                    value={key}
                    disabled={
                      +formik.values.ord === 3 && foreignL.includes(key)
                    }
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mb: '-24px' }}>
                {formik.errors.type}
              </FormHelperText>
            </FormControl>
            <TextField
              label={
                typesForFL.includes(formik.values.type)
                  ? 'ФИО'
                  : 'Название юр. лица'
              }
              required
              variant="standard"
              value={formik.values.name}
              name="name"
              onChange={handleChange}
              onBlur={(e) => {
                formik.handleBlur(e);
                onBlurName();
              }}
              helperText={formik.errors.name || ''}
              error={Boolean(formik.errors.name)}
              inputProps={{ maxLength: 255 }}
              sx={styleMuiFormHelperText}
              autoComplete="none"
            />
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0 30px" mb={4}>
            <TextField
              label="Синонимы названия (через запятую)"
              variant="standard"
              sx={styleMuiFormHelperText}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              name="aliases"
              helperText={formik.errors.aliases || ''}
              error={Boolean(formik.errors.aliases)}
              value={formik.values.aliases}
              inputProps={{ maxLength: 1000 }}
              autoComplete="none"
            />
            <TextField
              label="Название для выгрузки"
              variant="standard"
              sx={styleMuiFormHelperText}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              name="exportName"
              helperText={
                isChangeExportName.current && !formik.values.exportName
                  ? 'Если не заполнено будет подставлено значение из поля "Название юр. лица"'
                  : formik.errors.exportName
              }
              error={Boolean(formik.errors.exportName)}
              value={formik.values.exportName}
              inputProps={{ maxLength: 255 }}
              autoComplete="none"
            />
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0 30px">
            <FormControl variant="standard" error={Boolean(formik.errors.ord)}>
              <InputLabel id="ord-label" required>
                ОРД
              </InputLabel>
              <Select
                labelId="ord-label"
                label="ОРД*"
                name="ord"
                value={formik.values.ord}
                onChange={handleChange}
              >
                {ordList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mb: '-24px' }}>
                {formik.errors.ord}
              </FormHelperText>
            </FormControl>
            {['ul', 'fl', 'ip'].includes(formik.values.type) && (
              <TextField
                label="ИНН"
                required
                variant="standard"
                value={formik.values.inn}
                name="inn"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.inn || ''}
                error={Boolean(formik.errors.inn)}
                inputProps={{ maxLength: 40 }}
                sx={styleMuiFormHelperText}
                autoComplete="none"
              />
            )}
          </Box>

          {formik.values.type === 'ffl' && (
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="0 30px"
              mt={4}
            >
              <TextField
                label="Абонентский номер мобильного телефона"
                required
                variant="standard"
                value={formik.values.mobilePhone}
                name="mobilePhone"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.mobilePhone || ''}
                error={Boolean(formik.errors.mobilePhone)}
                inputProps={{ maxLength: 40 }}
                sx={{ ...styleMuiFormHelperText, ...styleDisabled }}
                autoComplete="none"
                disabled={Boolean(formik.values.epayNumber)}
              />
              <TextField
                label="EPAY"
                required
                variant="standard"
                value={formik.values.epayNumber}
                name="epayNumber"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.epayNumber || ''}
                error={Boolean(formik.errors.epayNumber)}
                inputProps={{ maxLength: 40 }}
                sx={{ ...styleMuiFormHelperText, ...styleDisabled }}
                autoComplete="none"
                disabled={Boolean(formik.values.mobilePhone)}
              />
            </Box>
          )}
          {formik.values.type === 'ful' && (
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="0 30px"
              mt={4}
            >
              <TextField
                label="Регистрационный номер"
                required
                variant="standard"
                value={formik.values.regNumber}
                name="regNumber"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.regNumber || ''}
                error={Boolean(formik.errors.regNumber)}
                inputProps={{ maxLength: 40 }}
                sx={{ ...styleMuiFormHelperText, ...styleDisabled }}
                autoComplete="none"
                disabled={Boolean(
                  formik.values.alternativeInn && formik.values.ord !== 4,
                )}
              />
              <TextField
                label="Номер налогоплательщика либо его аналог"
                required={formik.values.ord !== 4}
                variant="standard"
                value={formik.values.alternativeInn}
                name="alternativeInn"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.alternativeInn || ''}
                error={Boolean(formik.errors.alternativeInn)}
                inputProps={{ maxLength: 40 }}
                sx={{ ...styleMuiFormHelperText, ...styleDisabled }}
                autoComplete="none"
                disabled={Boolean(
                  formik.values.regNumber && formik.values.ord !== 4,
                )}
              />
            </Box>
          )}

          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap="0 30px"
            mt={4}
            mb={4}
          >
            {foreignL.includes(formik.values.type) && (
              <FormControl
                variant="standard"
                error={Boolean(formik.errors.oksmNumber)}
              >
                <InputLabel id="oksmNumber-label">
                  Страна регистрации
                </InputLabel>
                <Select
                  labelId="oksmNumber-label"
                  label="Страна регистрации"
                  name="oksmNumber"
                  value={formik.values.oksmNumber}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                >
                  {okms.map((item) => (
                    <MenuItem key={item.guid} value={item.code}>
                      {item.shortname}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ mb: '-24px' }}>
                  {formik.errors.oksmNumber}
                </FormHelperText>
              </FormControl>
            )}
            {formik.values.ord === 3 && (
              <FormControl
                variant="standard"
                error={Boolean(formik.errors.directClient)}
              >
                <InputLabel id="directClient-label">Прямой клиент</InputLabel>
                <Select
                  labelId="directClient-label"
                  label="Прямой клиент"
                  name="directClient"
                  value={formik.values.directClient ? 1 : 0}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={1}>ДА</MenuItem>
                  <MenuItem value={0}>НЕТ</MenuItem>
                </Select>
                <FormHelperText sx={{ mb: '-24px' }}>
                  {formik.errors.directClient}
                </FormHelperText>
              </FormControl>
            )}
          </Box>

          {[4, 5].includes(+formik.values.ord) && (
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="0 30px"
              mb={4}
              mt={4}
            >
              <FormControl variant="standard">
                <FormLabel component="legend">Тип контрагента</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isRr}
                        onChange={handleChecked}
                        name="isRr"
                      />
                    }
                    label="Рекламораспространитель"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isOrs}
                        onChange={handleChecked}
                        name="isOrs"
                      />
                    }
                    label="Оператор рекламной системы"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isRd}
                        onChange={formik.handleChange}
                        name="isRd"
                      />
                    }
                    label="Рекламодатель"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isRa}
                        onChange={formik.handleChange}
                        name="isRa"
                      />
                    }
                    label="Рекламное агентство"
                  />
                </FormGroup>
              </FormControl>

              {showRsUrl && (
                <TextField
                  label="Ссылка на рекламную систему"
                  variant="standard"
                  sx={styleMuiFormHelperText}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="rsUrl"
                  helperText={formik.errors.rsUrl || ''}
                  error={Boolean(formik.errors.rsUrl)}
                  value={formik.values.rsUrl}
                  inputProps={{ maxLength: 255 }}
                  autoComplete="none"
                />
              )}
            </Box>
          )}

          {showPlatform && (
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="0 30px"
              mb={4}
            >
              <FormControl
                variant="standard"
                error={Boolean(formik.errors.platforms)}
              >
                <InputLabel id="platforms-label">Платформы</InputLabel>
                <Select
                  labelId="platforms-label"
                  id="platforms-checkbox"
                  multiple
                  error={Boolean(formik.errors.platforms)}
                  value={formik.values.platforms}
                  onChange={handleChange}
                  name="platforms"
                  renderValue={(selected) => {
                    const names: string[] = [];
                    selected.forEach((value) => {
                      const name = platformOption.find(
                        (item) => item.value === value,
                      )?.label;
                      if (name) names.push(name);
                    });
                    return names.join(', ');
                  }}
                >
                  {platformOption.map(({ label, value }) => (
                    <MenuItem
                      key={value}
                      value={value}
                      sx={{ paddingY: '5px', paddingX: '8px' }}
                    >
                      <Checkbox
                        checked={Boolean(
                          formik.values.platforms?.includes(value),
                        )}
                        size="small"
                      />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ mb: '-24px' }}>
                  {formik.errors.platforms}
                </FormHelperText>
              </FormControl>
            </Box>
          )}
        </Paper>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '37px 0',
            maxHeight: 160,
          }}
        >
          <Avatar
            sx={{
              bgcolor: '#F1F4FF',
              width: 86,
              height: 86,
            }}
          >
            <PersonSharpIcon
              sx={{
                color: '#A1B2FC',
                fontSize: '2.5rem',
              }}
            />
          </Avatar>
        </Paper>
      </Box>

      <ReactRouterPrompt when={isPrompt}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            open={isActive}
            handleClose={onCancel}
            onConfirm={onConfirm}
            title="Подтвердите переход"
            outlinedText="Остаться на этой странице"
            containedText="Покинуть эту страницу"
          >
            Вы действительно хотите покинуть страницу?
          </Modal>
        )}
      </ReactRouterPrompt>
    </Box>
  );
};
