import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { FiDownload } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import Header from '../../../../components/Header';
import { OtherFilters } from '../OtherFilters';
import { SearchFilter } from './Search';
import { getReportContractListFx, resetWorkerForReport } from '../model/report';
import { addressesORD } from '../../../../addresses';

const ContractsHeader: FC = () => {
  const pending = useUnit(getReportContractListFx.pending);
  const onGetReport = () => {
    getReportContractListFx(undefined);
  };

  useEffect(() => resetWorkerForReport, []);
  return (
    <Header>
      <Typography variant="h1">Договоры</Typography>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <SearchFilter />
        <Tooltip
          title="Скачать"
          sx={{
            marginLeft: '15px',
          }}
        >
          <IconButton color="primary" onClick={onGetReport} disabled={pending}>
            <FiDownload />
          </IconButton>
        </Tooltip>

        <OtherFilters />

        <Button
          component={NavLink}
          to={addressesORD.contractCreatePath}
          variant="contained"
          sx={{
            marginLeft: '20px',
          }}
        >
          Добавить
        </Button>
      </Box>
    </Header>
  );
};

export default ContractsHeader;
