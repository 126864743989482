import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useUnit } from 'effector-react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  FixedHeaderContent,
  ItemContent,
  TableComponents,
  TableVirtuoso,
  VirtuosoHandle,
} from 'react-virtuoso';
import { Box } from '@mui/material';
import Item from './Item';
import { $organizationListFilter } from '../model';
import { ROrganizationGet } from '../../../../apiRPC/organization';
import css from './styles.module.scss';
import { FabSquare } from '../../../../UI/FabSquare';
import { navigateForEffect } from '../../../../model/routing';
import { generateOrganizationPath } from '../../../addresses';

const components: TableComponents<ROrganizationGet[] | null> = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      stickyHeader
      sx={{ minWidth: 650 }}
      aria-label="simple table"
    />
  ),
  TableHead,
  TableRow: ({ context, ...props }) => {
    const currentRow = context?.at(props['data-index']);
    const onClickRow: MouseEventHandler<HTMLTableRowElement> = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!currentRow?.uuid) return;
      navigateForEffect(generateOrganizationPath(currentRow.uuid));
    };
    return (
      <TableRow
        sx={{
          cursor: 'pointer',
          '&:last-child td, &:last-child th': { border: 0 },
        }}
        {...props}
        onDoubleClick={onClickRow}
      />
    );
  },
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const fixedHeader: FixedHeaderContent = () => (
  <TableRow>
    <TableCell align="left">Статус</TableCell>
    <TableCell>Название юр. лица</TableCell>
    <TableCell align="left">Название для выгрузки</TableCell>
    <TableCell align="left">Синонимы названия</TableCell>
    <TableCell align="left">ИНН / Альтернатива</TableCell>
    <TableCell align="left">ОРД</TableCell>
    <TableCell align="center" />
  </TableRow>
);

const ItemVirtual: ItemContent<ROrganizationGet, ROrganizationGet[] | null> = (
  index,
  row,
) => <Item row={row} key={row.uuid} />;
export type DataRow = {
  name: string;
  aliases: string;
  inn: string;
  ord: string;
  uuid: string;
};

export const BasicTable: FC = () => {
  const organizationList = useUnit($organizationListFilter);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [showBtn, setShowBtn] = useState(false);
  const onTop = () => {
    virtuosoRef.current?.scrollToIndex({
      index: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <TableVirtuoso
        ref={virtuosoRef}
        data={organizationList || []}
        components={components}
        fixedHeaderContent={fixedHeader}
        context={organizationList || []}
        itemContent={ItemVirtual}
        onScroll={(e) => {
          if (e.currentTarget.scrollTop > 300) {
            setShowBtn(true);
            return;
          }
          setShowBtn(false);
        }}
      />
      {showBtn && (
        <FabSquare
          color="primary"
          sx={{
            right: 0,
            bottom: 35,
            position: 'absolute',
            opacity: 0.4,
          }}
          onClick={onTop}
          className={css.tabToTop}
        >
          <ExpandLessIcon />
        </FabSquare>
      )}
      <Box
        sx={{
          marginBottom: '24px',
        }}
      />
    </>
  );
};
