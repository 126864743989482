import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useStoreMap, useUnit } from 'effector-react';
import {
  ContractItem,
  PContractCreate,
  PContractUpdate,
} from '../../../../../apiRPC/contracts';
import {
  $ordOption,
  $organizationData,
  OrganizationDataIten,
} from '../../model/catalogs';
import {
  $contract,
  $finalContract,
  $parentContract,
  contractUpdateFX,
} from './model';
import { navigateForEffect } from '../../../../../model/routing';
import { addressesORD } from '../../../../addresses';
import { $finalContractList, $parentContractList } from '../model';
import { isErrorProps } from '../../../../../apiRPC/request';
import { mapName } from '../../../Organization/options';

type UpdateForm = Omit<
  ContractItem,
  | 'uuid'
  | 'status'
  | 'customer'
  | 'executor'
  | 'ord'
  | 'date'
  | 'type'
  | 'actionType'
  | 'subjectType'
  | 'executorIsObligedForRegistration'
  | 'isInvoiceContract'
  | 'agentActingForPublisher'
  | 'category'
  | 'parent'
  | 'finalContract'
  | 'useNetworkStat'
> & {
  customer: OrganizationDataIten | undefined;
  executor: OrganizationDataIten | undefined;
  ord: ContractItem['ord'] | undefined;
  date: Date | undefined;
  type: ContractItem['type'] | undefined;
  actionType: ContractItem['actionType'] | undefined;
  subjectType: ContractItem['subjectType'] | undefined;
  executorIsObligedForRegistration: 0 | 1 | undefined;
  isInvoiceContract: 0 | 1 | undefined;
  agentActingForPublisher: 0 | 1 | undefined;
  category: PContractCreate['fields']['category'] | undefined;
  parent: ContractItem | undefined;
  finalContract: ContractItem | undefined;
  useNetworkStat: 0 | 1;
};
const initialValues: UpdateForm = {
  ord: undefined, // Основная ОРД-
  externalId: '', // Идентификатор договора в ORD-
  customer: undefined, // Заказчик-
  executor: undefined, // Исполнитель-
  number: '', // Номер договора
  date: undefined, // Дата договора
  type: undefined, // Тип договора

  actionType: undefined, // Действия, осуществляемые посредником-представителем
  subjectType: undefined, // Сведения о предмете договора
  amount: 0, // Сумма договора (руб)
  executorIsObligedForRegistration: undefined, // Обязанность регистрировать и репортировать креативы исполнителем
  isInvoiceContract: undefined, // Договор отчета. По умолчанию - false
  agentActingForPublisher: undefined, // Направление денежных средств.
  finalContract: undefined, // Доходный договор.
  category: undefined, // Категория договора.
  parent: undefined, // Родительский договор.
  useNetworkStat: 0,
};

function formIsChanged(
  formVal: UpdateForm,
  contract: ContractItem | null,
): boolean {
  let res = false;
  if (!contract) return res;

  const checkedObj: Omit<PContractUpdate['fields'], 'date'> & { date: number } =
    {
      type: formVal.type,
      date: +formVal.date! / 1000,
      executorIsObligedForRegistration: Boolean(
        formVal.executorIsObligedForRegistration,
      ),
      isInvoiceContract: Boolean(formVal.isInvoiceContract),
      actionType: formVal.actionType,
      subjectType: formVal.subjectType,
      number: formVal.number,
      amount: formVal.amount,
      agentActingForPublisher: Boolean(formVal.agentActingForPublisher),
      finalContract: formVal.finalContract?.uuid ?? null,
      parent: formVal.parent?.uuid ?? '',
      useNetworkStat: Boolean(formVal.useNetworkStat),
    };

  const keys = Object.keys(checkedObj);
  for (const key of keys) {
    if (
      checkedObj[key as keyof PContractUpdate['fields']] !==
      contract[key as keyof UpdateForm]
    ) {
      res = true;
      break;
    }
  }

  return res;
}

export function useUpdate() {
  const { uuid } = useParams();
  const [disableModal, setDisableModal] = useState(false);
  const formik = useFormik<UpdateForm>({
    initialValues,
    validationSchema: Yup.object({
      ord: Yup.number().nullable().required('Обязательное для заполнения поле'),
      externalId: Yup.string().nullable(),
      customer: Yup.object()
        .nullable()
        .required('Обязательное для заполнения поле'),
      executor: Yup.object()
        .nullable()
        .required('Обязательное для заполнения поле'),
      number: Yup.string(),
      date: Yup.date()
        .typeError('Некорректное значение')
        .nullable()
        .required('Обязательное для заполнения поле'),
      type: Yup.string()
        .nullable()
        .required('Обязательное для заполнения поле'),
      actionType: Yup.string()
        .nullable()
        .required('Обязательное для заполнения поле'),
      subjectType: Yup.string()
        .nullable()
        .required('Обязательное для заполнения поле'),
      amount: Yup.number()
        .min(0, 'Минимальное значение 0')
        .max(1e16, 'Превышено максимальное значение')
        .typeError('Некорректное значение')
        .required('Обязательное для заполнения поле'),
      executorIsObligedForRegistration: Yup.number().nullable(),
      isInvoiceContract: Yup.number().nullable(),
      agentActingForPublisher: Yup.number().required(
        'Обязательное для заполнения поле',
      ),
      parent: Yup.object()
        .shape({ uuid: Yup.string().nullable() })
        .when('type', ([type], schema) =>
          schema.test({
            test: (value) => !(!value?.uuid && type === 'Additional'),
            message: 'Обязательное для заполнения поле',
          }),
        )
        .nullable(),
      finalContract: Yup.object()
        .when(['ord', 'category'], {
          is: (ord: number, category: string) =>
            // eslint-disable-next-line eqeqeq
            ord == 3 && category === 'Initial',
          then: (schema) => schema.required('Обязательное для заполнения поле'),
          otherwise: (schema) => schema.notRequired(),
        })
        .notRequired(),
      useNetworkStat: Yup.number(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      try {
        setDisableModal(true);
        const fields: PContractUpdate['fields'] = {
          type: values.type ?? undefined,
          date: format(values.date!, 'yyyy-MM-dd'),
          executorIsObligedForRegistration: Boolean(
            values.executorIsObligedForRegistration,
          ),
          actionType: values.actionType ?? undefined,
          subjectType: values.subjectType ?? undefined,
          number: values.number ?? '',
          amount: values.amount ?? undefined,
          isInvoiceContract:
            values.isInvoiceContract !== undefined
              ? Boolean(values.isInvoiceContract)
              : undefined,
          agentActingForPublisher: Boolean(values.agentActingForPublisher),
          finalContract: values?.finalContract?.uuid ?? undefined,
          parent: values?.parent?.uuid ?? undefined,
          useNetworkStat: Boolean(values.useNetworkStat),
        };

        await contractUpdateFX({ uuid: uuid || '', fields });

        toast.success('Договор был изменен', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigateForEffect(addressesORD.contractsPath);
      } catch (e) {
        setDisableModal(false);
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            let text = field.description;
            Array.from(mapName).forEach(([key, val]) => {
              text = text.replace(key, val);
            });
            f.setFieldError(field.field, text);
          });
        }
        console.error(e);
      }
    },
  });

  const [openInfo, setOpenInfo] = useState(true);

  const contract = useUnit($contract);

  const [finalContract, parentContract] = useUnit([
    $finalContract,
    $parentContract,
  ]);

  const [finalContractList, parentContractList] = useUnit([
    $finalContractList,
    $parentContractList,
  ]);
  const customer = useStoreMap({
    store: $organizationData,
    keys: [contract?.customer],
    fn: (state, keys) => {
      const key = keys.at(0);
      if (!key) return null;
      return state?.[key];
    },
    updateFilter: (newResult, oldResult) => newResult?.name !== oldResult?.name,
  });
  const executor = useStoreMap({
    store: $organizationData,
    keys: [contract?.executor],
    fn: (state, keys) => {
      const key = keys.at(0);
      if (!key) return null;
      return state?.[key];
    },
    updateFilter: (newResult, oldResult) => newResult?.name !== oldResult?.name,
  });
  useEffect(() => {
    if (!contract) return;

    formik.setValues({
      ord: contract.ord,
      externalId: contract.externalId,
      customer: formik.values.customer,
      executor: formik.values.executor,
      number: contract.number,
      date: new Date(contract.date * 1000),
      type: contract.type,
      actionType: contract.actionType,
      subjectType: contract.subjectType,
      amount: contract.amount,
      executorIsObligedForRegistration:
        contract.executorIsObligedForRegistration ? 1 : 0,
      isInvoiceContract: contract.isInvoiceContract ? 1 : 0,
      agentActingForPublisher: contract.agentActingForPublisher ? 1 : 0,
      finalContract: formik.values.finalContract,
      category: contract.category,
      parent: formik.values.parent,
      useNetworkStat: contract.useNetworkStat ? 1 : 0,
    });
  }, [contract]);

  const resetForm = () => {
    if (!contract) return;
    formik.setValues({
      ord: contract.ord,
      externalId: contract.externalId,
      customer: formik.values.customer,
      executor: formik.values.executor,
      number: contract.number,
      date: new Date(contract.date * 1000),
      type: contract.type,

      actionType: contract.actionType,
      subjectType: contract.subjectType,
      amount: contract.amount,
      executorIsObligedForRegistration:
        contract.executorIsObligedForRegistration ? 1 : 0,
      isInvoiceContract: contract.isInvoiceContract ? 1 : 0,
      agentActingForPublisher: contract.agentActingForPublisher ? 1 : 0,
      finalContract: finalContract || undefined,
      category: contract.category,
      parent: parentContract || undefined,
      useNetworkStat: contract.useNetworkStat ? 1 : 0,
    });
  };
  useEffect(() => {
    if (!customer) return;

    formik.setFieldValue('customer', customer);
  }, [customer]);
  useEffect(() => {
    if (!finalContract) return;
    formik.setFieldValue('finalContract', finalContract);
  }, [finalContract]);
  useEffect(() => {
    if (!parentContract) return;
    formik.setFieldValue('parent', parentContract);
  }, [parentContract]);

  useEffect(() => {
    if (!executor) return;
    formik.setFieldValue('executor', executor);
  }, [executor]);

  const ordOption = useUnit($ordOption);

  const handleChange: (typeof formik)['handleChange'] = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;

      formik.setFieldValue(name, value).then(() => {
        formik.validateField(name);
        if (name === 'ord') {
          formik.validateField('number');
        }
      });

      if (
        name === 'type' &&
        value === 'Intermediary' &&
        formik.values.actionType === 'None'
      ) {
        formik.setFieldValue('actionType', '');
      }
    },
    [formik],
  );

  const handleChangeForAmount: (typeof formik)['handleChange'] = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const d = value.split('.').at(1);
      if ((d?.length ?? 0) > 4) return;
      formik.setFieldValue(name, value).then(() => {
        formik.validateField(name);
      });
    },
    [formik],
  );

  const pendingUpdate = useUnit(contractUpdateFX.pending);
  const formChanged = formIsChanged(formik.values, contract);
  const btnDisabled = !formChanged || pendingUpdate;
  const isPrompt = disableModal ? false : formChanged;
  const btnSendDisabled = btnDisabled || !formik.isValid;
  return {
    formik,
    contract,
    btnDisabled,
    btnSendDisabled,
    pendingUpdate,
    resetForm,
    openInfo,
    setOpenInfo,
    uuid,
    handleChange,
    ordOption,
    handleChangeForAmount,
    isPrompt,
    finalContractList,
    parentContractList,
  };
}
