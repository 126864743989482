import { combine, createEffect, createEvent, restore, sample } from 'effector';
import { Filter } from '../../../../../../apiRPC/contracts';
import { $lastFilter, contractGetListFX, limitContracts } from '../index';

export const onSetStatusFilter = createEvent<Filter['status'] | null>();
export const onResetStatusFilter = createEvent();
export const $statusFilter = restore(onSetStatusFilter, null).reset(
  onResetStatusFilter,
);
export const onSetErirStatusFilter = createEvent<Filter['erirStatus'] | null>();
export const onResetErirStatusFilter = createEvent();
export const $erirStatusFilter = restore(onSetErirStatusFilter, null).reset(
  onResetErirStatusFilter,
);

export const onSetCategoryFilter = createEvent<Filter['category'] | null>();
export const onResetCategoryFilter = createEvent();
export const $CategoryFilter = restore(onSetCategoryFilter, null).reset(
  onResetCategoryFilter,
);

export const onSetCustomerInnFilter = createEvent<string | null>();
export const onResetCustomerInnFilter = createEvent();
export const $customerInnFilter = restore(onSetCustomerInnFilter, null).reset(
  onResetCustomerInnFilter,
);

export const onSetExecutorInnFilter = createEvent<string | null>();
export const onResetExecutorInnFilter = createEvent();
export const $executorInnFilter = restore(onSetExecutorInnFilter, null).reset(
  onResetExecutorInnFilter,
);

export const onSetOrdFilter = createEvent<number | null>();
export const onResetOrdFilter = createEvent();
export const $ordFilter = restore(onSetOrdFilter, null).reset(onResetOrdFilter);

export function resetOtherFilter() {
  onResetErirStatusFilter();
  onResetCategoryFilter();
  onResetCustomerInnFilter();
  onResetExecutorInnFilter();
  onResetOrdFilter();
  onResetStatusFilter();
}
const namesFilters: (keyof Filter)[] = [
  'ord',
  'customerInn',
  'executorInn',
  'category',
  'erirStatus',
];

export const $countOtherFilter = combine($lastFilter, (lastFilter) => {
  if (!lastFilter) return 0;

  return namesFilters.reduce<number>(
    (accumulator, currentValue) =>
      accumulator +
      (lastFilter.filter?.[currentValue] === null ||
      lastFilter.filter?.[currentValue] === undefined
        ? 0
        : 1),
    0,
  );
});

export const onResetOtherFilter = createEvent();
onResetOtherFilter.watch(resetOtherFilter);
sample({
  clock: onResetOtherFilter,
  source: {
    lastFilter: $lastFilter,
    countOtherFilter: $countOtherFilter,
  },
  filter: ({ countOtherFilter, lastFilter }) =>
    Boolean(countOtherFilter) &&
    Boolean(
      namesFilters.find(
        (item) =>
          lastFilter?.filter?.[item] !== undefined ||
          lastFilter?.filter?.[item] !== null,
      ),
    ),
  fn: ({ lastFilter }) => {
    const oldFilter = { ...(lastFilter?.filter || {}) };
    namesFilters.forEach((item) => delete oldFilter?.[item]);
    const filter = {
      ...oldFilter,
      limit: limitContracts,
      offset: 0,
    };
    return { params: { filter } };
  },
  target: contractGetListFX,
});

export const onMapOtherFilters = createEvent();
sample({
  clock: onMapOtherFilters,
  source: {
    lastFilter: $lastFilter,
  },
  fn: ({ lastFilter }) => lastFilter?.filter,
  target: createEffect((filter: Filter | undefined) => {
    onSetStatusFilter(filter?.status ?? null);
    onSetErirStatusFilter(filter?.erirStatus ?? null);
    onSetCategoryFilter(filter?.category ?? null);
    onSetCustomerInnFilter(filter?.customerInn ?? null);
    onSetExecutorInnFilter(filter?.executorInn ?? null);
    onSetOrdFilter(filter?.ord ?? null);
  }),
});

export const onSendOtherFilter = createEvent();
sample({
  clock: onSendOtherFilter,
  source: {
    status: $statusFilter,
    erirStatusFilter: $erirStatusFilter,
    CategoryFilter: $CategoryFilter,
    customerInnFilter: $customerInnFilter,
    executorInnFilter: $executorInnFilter,
    ordFilter: $ordFilter,
    lastFilter: $lastFilter,
  },
  filter: ({
    status,
    erirStatusFilter,
    CategoryFilter,
    customerInnFilter,
    executorInnFilter,
    ordFilter,
    lastFilter,
  }) => {
    const filter = lastFilter?.filter;
    if ((filter?.status ?? null) !== status) return true;
    if ((filter?.erirStatus ?? null) !== erirStatusFilter) return true;
    if ((filter?.category ?? null) !== CategoryFilter) return true;
    if ((filter?.customerInn ?? null) !== customerInnFilter) return true;
    if ((filter?.executorInn ?? null) !== executorInnFilter) return true;
    if ((filter?.ord ?? null) !== ordFilter) return true;

    return false;
  },
  fn: ({
    status,
    erirStatusFilter,
    CategoryFilter,
    customerInnFilter,
    executorInnFilter,
    ordFilter,
    lastFilter,
  }) => {
    const oldFilter = { ...(lastFilter?.filter || {}) };
    if (status === null) {
      delete oldFilter.status;
    } else {
      oldFilter.status = status;
    }
    if (erirStatusFilter === null) {
      delete oldFilter.erirStatus;
    } else {
      oldFilter.erirStatus = erirStatusFilter;
    }
    if (!CategoryFilter) {
      delete oldFilter.category;
    } else {
      oldFilter.category = CategoryFilter;
    }
    if (customerInnFilter === null) {
      delete oldFilter.customerInn;
    } else {
      oldFilter.customerInn = customerInnFilter;
    }
    if (executorInnFilter === null) {
      delete oldFilter.executorInn;
    } else {
      oldFilter.executorInn = executorInnFilter;
    }
    if (ordFilter === null) {
      delete oldFilter.ord;
    } else {
      oldFilter.ord = ordFilter;
    }
    const filter = {
      ...oldFilter,
      limit: limitContracts,
      offset: 0,
    };
    return { params: { filter } };
  },
  target: contractGetListFX,
});
