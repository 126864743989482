import { Creative, CreativeItem } from '../../../apiRPC/creative';
import { CreativeInfoStatuses } from './Creative/Update/CreativeInfo';

export const dataTypes: { label: Creative['type']; value: Creative['type'] }[] =
  [
    {
      label: 'cpa',
      value: 'cpa',
    },
    {
      label: 'cpc',
      value: 'cpc',
    },
    {
      label: 'cpm',
      value: 'cpm',
    },
    {
      label: 'other',
      value: 'other',
    },
  ];
export const dataForm: { label: Creative['form']; value: Creative['form'] }[] =
  [
    {
      label: 'banner',
      value: 'banner',
    },

    {
      label: 'text-graphic-block',
      value: 'text-graphic-block',
    },
    {
      label: 'video',
      value: 'video',
    },
    // {
    //   label: 'text-block',
    //   value: 'text-block',
    // },
    // {
    //   label: 'audio-rec',
    //   value: 'audio-rec',
    // },
    // {
    //   label: 'live-audio',
    //   value: 'live-audio',
    // },
    // {
    //   label: 'live-video',
    //   value: 'live-video',
    // },
    // {
    //   label: 'other',
    //   value: 'other',
    // },
  ];

export const loadingStatusMapText = new Map<
  CreativeItem['loadingStatus'],
  string
>([
  ['NONE', 'Креатив не отправляется'],
  ['PENDING', 'Креатив ожидает выгрузки'],
  ['PENDING_RESOURCE', 'Креатив ожидает выгрузки ресурсов'],
  ['SUCCESS', 'Креатив выгружен успешно'],
  ['ERROR', 'Ошибка выгрузки креатива'],
  ['ON_DEV', 'Сущность на утверждении у менеджеров API'],
  ['ON_APPROVAL', 'На модерации'],
]);
export const loadingStatusMapStat = new Map<
  CreativeItem['loadingStatus'],
  CreativeInfoStatuses
>([
  ['NONE', '_isError'],
  ['PENDING', '_isWarring'],
  ['PENDING_RESOURCE', '_isWarring'],
  ['SUCCESS', '_isSuccess'],
  ['ERROR', '_isError'],
  ['ON_APPROVAL', '_isWarring'],
]);

export const erirStatusMapText = new Map<CreativeItem['erirStatus'], string>([
  ['NONE', 'Не отправляется в ЕРИР'],
  ['PENDING', 'В ожидании выгрузки в ЕРИР'],
  ['SUCCESS', 'Экспортировано в ЕРИР'],
  ['ERROR', 'Ошибка экспорта в ЕРИР'],
]);
export const erirStatusMapStat = new Map<
  CreativeItem['erirStatus'],
  CreativeInfoStatuses
>([
  ['NONE', '_isError'],
  ['PENDING', '_isWarring'],
  ['SUCCESS', '_isSuccess'],
  ['ERROR', '_isError'],
]);
