import { Form } from './types';
import { Category } from '../../../apiRPC/contracts';

export const useNetworkStatOptions: {
  label: string;
  value: Form['useNetworkStat'];
}[] = [
  { label: 'Да', value: 1 },
  { label: 'Нет', value: 0 },
];
export const executorIsObligedForRegistrationOptions: {
  label: string;
  value: Form['executorIsObligedForRegistration'];
}[] = [
  { label: 'Да', value: 1 },
  { label: 'Нет', value: 0 },
];

export const isInvoiceContractOptions: {
  label: string;
  value: Form['isInvoiceContract'];
}[] = [
  { label: 'Да', value: 1 },
  { label: 'Нет', value: 0 },
];

export const subjectTypeOptions: {
  label: string;
  value: Form['subjectType'];
}[] = [
  { label: 'Иное', value: 'Other' },
  { label: 'Представительство', value: 'Representation' },
  {
    label: 'Организация распространения',
    value: 'DistributionOrganization',
  },
  { label: 'Посредничество', value: 'Mediation' },
  { label: 'Распространение', value: 'Distribution' },
];
export const typeOptions: { label: string; value: Form['type'] }[] = [
  { label: 'Посреднический договор', value: 'Intermediary' },
  { label: 'Договор оказания услуг', value: 'Original' },
  { label: 'Дополнительное соглашение', value: 'Additional' },
];

export const actionTypeOptions: { label: string; value: Form['actionType'] }[] =
  [
    { label: 'Иное', value: 'Other' },
    {
      label: 'Действия в целях распространения рекламы',
      value: 'Distribution',
    },
    { label: 'Заключение договоров', value: 'Conclude' },
    { label: 'Коммерческое представительство', value: 'Commercial' },
    { label: 'Нет', value: 'None' },
  ];

export const optionsCategoryCreate: {
  label: string;
  value: Category;
}[] = [
  {
    label: 'Изначальный договор',
    value: 'Initial',
  },
  {
    label: 'Доходный договор',
    value: 'Final',
  },
];
export const optionsCategoryUpdate: {
  label: string;
  value: Category;
}[] = [
  {
    label: 'Изначальный договор',
    value: 'Initial',
  },
  {
    label: 'Доходный договор',
    value: 'Final',
  },
  {
    label: 'Расходный договор',
    value: 'Outer',
  },
  {
    label: 'Расходный договор',
    value: 'Outer',
  },
  {
    label: 'Договор саморекламы',
    value: 'SelfPromotion',
  },
];
export const optionsStatus: {
  label: string;
  value: 'ACTIVE' | 'DELETED' | 'ON_APPROVAL';
}[] = [
  {
    label: 'Активен',
    value: 'ACTIVE',
  },
  {
    label: 'Удален',
    value: 'DELETED',
  },
  {
    label: 'На модерации',
    value: 'ON_APPROVAL',
  },
];
export const optionsAgentActingForPublisher: {
  label: string;
  value: 0 | 1;
}[] = [
  {
    label: 'Агент действует в интересах РР',
    value: 1,
  },
  {
    label: 'Агент действует в интересах РД',
    value: 0,
  },
];

export const mapName = new Map([
  ['oksmNumber', 'Поле "Страна регистрации"'],
  ['inn', 'Поле "ИНН"'],
]);
