import React, { FC } from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { FiChevronLeft, FiExternalLink } from 'react-icons/fi';
import { LoadingButton } from '@mui/lab';
import ReactRouterPrompt from 'react-router-prompt';
import Header from '../../../../components/Header';
import css from '../styles.module.scss';
import { Info } from '../../../../../components/Info';
import { SelectMS } from '../../../../../UI/form/SelectMS';
import {
  actionTypeOptions,
  executorIsObligedForRegistrationOptions,
  isInvoiceContractOptions,
  optionsAgentActingForPublisher,
  optionsCategoryUpdate,
  subjectTypeOptions,
  typeOptions,
  useNetworkStatOptions,
} from '../../../Organization/options';

import Modal from '../../../../../components/Modal';
import { ErirStatys } from './components/ErirStatys';
import { useUpdate } from './useUpdate';
import { addressesORD, generateOrganizationPath } from '../../../../addresses';
import CalendarField from '../../../../../components/CalendarField';
import { ContractAutocomplete } from '../../../../components/ContractAutocomplete';
import { LabelForContracts } from '../../../Campaigns/UI/LabelForContracts';
import {
  onLoadFinalContracts,
  onLoadParentContracts,
  searchFinalContract,
  searchParentContract,
} from '../model';
import {
  setDefaultsFinalContractList,
  setDefaultsParentContractsList,
} from './model';

export const UpdateForm: FC = () => {
  const theme = useTheme();
  const {
    formik,
    contract,
    btnDisabled,
    pendingUpdate,
    resetForm,
    openInfo,
    setOpenInfo,
    uuid,
    handleChange,
    ordOption,
    handleChangeForAmount,
    isPrompt,
    btnSendDisabled,
    finalContractList,
    parentContractList,
  } = useUpdate();

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Header>
        <Box display="flex">
          <NavLink to={addressesORD.contractsPath}>
            <Tooltip title="Назад">
              <IconButton>
                <FiChevronLeft size={20} />
              </IconButton>
            </Tooltip>
          </NavLink>
          <Box flex={1}>
            <Typography
              className={css.name}
              variant="h1"
              sx={{
                marginLeft: '2px',
              }}
              title={contract?.number}
            >
              Договор {contract?.number}
            </Typography>
            <Typography
              mt={0.5}
              sx={{
                color: theme.palette.secondary.light,
              }}
            >
              {contract?.uuid}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="outlined"
            disabled={btnDisabled}
            sx={{
              marginLeft: pendingUpdate ? '36px' : '0px',
            }}
            onClick={resetForm}
          >
            Отмена
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={btnSendDisabled}
            loading={pendingUpdate}
            sx={{
              marginLeft: '36px',
            }}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </Header>
      {openInfo && (
        <Info
          text="Если договор создавали в ЛК ОРД, то надо вручную обновить
        параметры через ЛК ОРД"
          setOpenInfo={setOpenInfo}
        />
      )}
      <Paper
        elevation={0}
        sx={{
          padding: '30px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'center',
            marginBottom: '40px',
          }}
        >
          <Typography fontWeight={700}>Параметры</Typography>
          <ErirStatys
            erirStatus={contract?.erirStatus}
            erirExportedOn={contract?.erirExportedOn}
            uuid={uuid}
          />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '30px',
          }}
        >
          <SelectMS
            disabled
            error={Boolean(formik.errors.ord)}
            label="Основная ОРД"
            value={formik.values.ord ?? ''}
            name="ord"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={ordOption}
            helperText={formik.errors.ord}
          />
          <TextField
            disabled
            label="Идентификатор договора в ОРД"
            variant="standard"
            value={formik.values.externalId ?? ''}
            name="externalId"
            inputProps={{ maxLength: 40 }}
            autoComplete="none"
          />

          <Box
            sx={{
              position: 'relative',
              display: 'grid',
            }}
          >
            <TextField
              disabled
              label="Заказчик"
              variant="standard"
              value={`${formik.values.customer?.name || ''} ${
                formik.values.customer?.inn || ''
              }`}
              name="customer"
              autoComplete="none"
              sx={{
                input: {
                  paddingRight: '40px',
                },
              }}
            />
            <NavLink
              to={generateOrganizationPath(contract?.customer || '')}
              target="_blank"
            >
              <FiExternalLink
                size={20}
                color={theme.palette.primary.main}
                className={css.link}
              />
            </NavLink>
          </Box>

          <Box
            sx={{
              position: 'relative',
              display: 'grid',
            }}
          >
            <TextField
              disabled
              label="Исполнитель"
              variant="standard"
              value={`${formik.values.executor?.name ?? ''}  ${
                formik.values.executor?.inn ?? ''
              }`}
              name="executor"
              autoComplete="none"
              sx={{
                input: {
                  paddingRight: '40px',
                },
              }}
            />

            <NavLink
              to={generateOrganizationPath(contract?.executor || '')}
              target="_blank"
            >
              <FiExternalLink
                size={20}
                color={theme.palette.primary.main}
                className={css.link}
              />
            </NavLink>
          </Box>
          <TextField
            label="Номер договора"
            variant="standard"
            value={formik.values.number ?? ''}
            name="number"
            inputProps={{ maxLength: 255 }}
            autoComplete="none"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.number)}
            helperText={formik.errors.number}
          />
          <CalendarField
            required
            label="Дата договора"
            value={formik.values.date}
            onChange={(v) => {
              formik.setFieldValue('date', v).then(() => {
                formik.validateField('date');
              });
            }}
            error={formik.errors.date}
            helperText={formik.errors.date}
          />
          <SelectMS
            error={Boolean(formik.errors.type)}
            required
            label="Тип договора"
            value={formik.values.type ?? ''}
            name="type"
            disabledOption={(value) => false}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={typeOptions.filter(
              (item) => item.value !== 'SelfPromotion',
            )}
            helperText={formik.errors.type}
          />

          <SelectMS
            error={Boolean(formik.errors.category)}
            required
            disabled
            label="Категория договора"
            value={formik.values.category || ''}
            name="category"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={optionsCategoryUpdate}
            helperText={formik.errors.category}
          />
          <SelectMS
            error={Boolean(formik.errors.agentActingForPublisher)}
            required
            label="Направление денежных средств"
            value={formik.values.agentActingForPublisher ?? ''}
            name="agentActingForPublisher"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={optionsAgentActingForPublisher}
            helperText={formik.errors.agentActingForPublisher}
          />

          <Box
            sx={{
              position: 'relative',
            }}
          >
            <ContractAutocomplete
              value={formik.values.finalContract || null}
              options={finalContractList}
              onInputChange={(event, value, reason) => {
                if (reason === 'clear') setDefaultsFinalContractList();
              }}
              sx={{
                '.MuiInputLabel-root': { zIndex: 99 },
              }}
              label={
                <LabelForContracts
                  text="Доходный договор"
                  required={formik.values.ord === 3}
                  title="№ Договора, ИНН"
                />
              }
              placeholder="Название / ИНН"
              onChange={(_, v) => {
                formik.setFieldValue('finalContract', v).then(() => {
                  formik.validateField('finalContract');
                });
              }}
              error={Boolean(formik.errors.finalContract)}
              helperText={formik.errors.finalContract}
              onScrollEnd={onLoadFinalContracts}
              search={searchFinalContract}
              getOptionLabel={(option) =>
                `${option?.number} • ${option?.customerInn}`
              }
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  key={option?.uuid}
                  component="li"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {option?.number} • {option?.customerInn}
                </ListItem>
              )}
            />
          </Box>

          <Box
            sx={{
              position: 'relative',
            }}
          >
            <ContractAutocomplete
              onInputChange={(event, value, reason) => {
                if (reason === 'clear') setDefaultsParentContractsList();
              }}
              options={parentContractList}
              value={formik.values.parent || null}
              sx={{
                '.MuiInputLabel-root': { zIndex: 99 },
              }}
              label={
                <LabelForContracts
                  text="Родительский договор"
                  required={formik.values.type === 'Additional'}
                  title="№ Договора, ИНН"
                />
              }
              placeholder="Название / ИНН"
              onChange={(_, v) => {
                formik.setFieldValue('parent', v).then(() => {
                  formik.validateField('parent');
                });
              }}
              error={Boolean(formik.errors.parent)}
              helperText={formik.errors.parent}
              onScrollEnd={onLoadParentContracts}
              search={searchParentContract}
              getOptionLabel={(option) =>
                `${option?.number} • ${option?.customerInn}`
              }
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  key={option?.uuid}
                  component="li"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {option?.number} • {option?.customerInn}
                </ListItem>
              )}
            />
          </Box>

          {/*! !!!!!!!! */}

          <SelectMS
            error={Boolean(formik.errors.actionType)}
            required
            label="Действия, осуществляемые посредником-представителем"
            value={formik.values.actionType ?? ''}
            name="actionType"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={actionTypeOptions}
            disabledOption={(value) =>
              formik.values.type === 'Intermediary' && value === 'None'
            }
            helperText={formik.errors.actionType}
          />
          <SelectMS
            error={Boolean(formik.errors.isInvoiceContract)}
            label="Договор отчета"
            value={formik.values.isInvoiceContract ?? ''}
            name="isInvoiceContract"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={isInvoiceContractOptions}
            helperText={formik.errors.isInvoiceContract}
          />
          <SelectMS
            error={Boolean(formik.errors.subjectType)}
            required
            label="Сведения о предмете договора"
            value={formik.values.subjectType ?? ''}
            name="subjectType"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={subjectTypeOptions}
            helperText={formik.errors.subjectType}
          />
          <TextField
              label={
                <LabelForContracts text="Сумма договора (руб)" title="Указывайте итоговую сумму с учетом всех налогов" />
              }
            variant="standard"
            value={formik.values.amount}
            name="amount"
            inputProps={{
              maxLength: 30,
            }}
            autoComplete="none"
            error={Boolean(formik.errors.amount)}
            helperText={formik.errors.amount}
            onChange={handleChangeForAmount}
            onBlur={formik.handleBlur}
          />

          <SelectMS
            label="Обязанность регистрировать и репортить креативы исполнителем"
            value={formik.values.executorIsObligedForRegistration ?? ''}
            name="executorIsObligedForRegistration"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={executorIsObligedForRegistrationOptions}
          />
          <SelectMS
            label="Отчет на основе network"
            value={formik.values.useNetworkStat}
            name="useNetworkStat"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={useNetworkStatOptions}
          />
        </Box>
      </Paper>
      <ReactRouterPrompt when={isPrompt}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            open={isActive}
            handleClose={onCancel}
            onConfirm={onConfirm}
            title="Подтвердите переход"
            outlinedText="Остаться на этой странице"
            containedText="Покинуть эту страницу"
          >
            Вы действительно хотите покинуть страницу?
          </Modal>
        )}
      </ReactRouterPrompt>
    </Box>
  );
};
