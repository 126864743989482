import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useUnit } from 'effector-react';
import { $statusFilter, onSetStatusFilter } from '../model';

const TabHeader = () => {
  const statusFilter = useUnit($statusFilter);
  return (
    <Box
      sx={{
        background: '#3F55B5',
        boxShadow: '45px 0px 20px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Tabs
        value={statusFilter}
        onChange={(_, val) => onSetStatusFilter(val)}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="primary tabs example"
      >
        <Tab value="ACTIVE" label="Активные" />
        <Tab value="ON_APPROVAL" label="На модерации" />
        <Tab value="DELETED" label="Удаленные" />
        <Tab value={null} label="Все" />
      </Tabs>
    </Box>
  );
};
export default TabHeader;
