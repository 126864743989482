import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import CheckIcon from '@mui/icons-material/Check';
import React, { FC, useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { useUnit } from 'effector-react';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import type { ROrganizationGet } from '../../../../apiRPC/organization';
import { $ordList, getOrdNameById } from '../../../model/ord';
import css from './styles.module.scss';
import { BootstrapTooltip } from '../../../../components/BootstrapTooltip';
import { generateOrganizationPath } from '../../../addresses';
import { StatusMarker } from '../../../components/StatusMarker';

type props = {
  row: ROrganizationGet;
};

const MainOrganizationAvatar: FC = () => (
  <BootstrapTooltip title="Основная организация" placement="bottom-start">
    <Avatar
      sx={{
        bgcolor: '#F1F4FF',
        width: 32,
        height: 32,
        marginRight: '20px',
      }}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<CheckIcon color="primary" />}
      >
        <PersonSharpIcon
          sx={{
            color: '#A1B2FC',
            fontSize: '1.5rem',
          }}
        />
      </Badge>
    </Avatar>
  </BootstrapTooltip>
);
const OrganizationAvatar: FC = () => (
  <Avatar
    sx={{
      bgcolor: '#F1F4FF',
      width: 32,
      height: 32,
      marginRight: '20px',
    }}
  >
    <PersonSharpIcon
      sx={{
        color: '#A1B2FC',
        fontSize: '1.5rem',
      }}
    />
  </Avatar>
);
const Item: FC<props> = ({ row }) => {
  const [toggle, setToggle] = useState('');
  const theme = useTheme();
  const ordList = useUnit($ordList);
  return (
    <>
      <TableCell component="th" scope="row" width="70px">
        <StatusMarker status={row.status} />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        size="small"
        sx={{ width: 400, maxWidth: 'fit-content' }}
      >
        <Box display="flex" alignItems="center">
          {row.main ? <MainOrganizationAvatar /> : <OrganizationAvatar />}
          <Box
            sx={{
              maxWidth: '400px',
            }}
          >
            <BootstrapTooltip title={row.name} placement="bottom-start">
              <Box className={css.name}>{row.name}</Box>
            </BootstrapTooltip>
            <Typography
              sx={{
                color: theme.palette.secondary.light,
                fontSize: '14px',
                maxWidth: '300px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {row.uuid}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="left" sx={{ width: 200 }} size="small">
        <BootstrapTooltip title={row.exportName} placement="bottom-start">
          <Box className={css.inn}>{row.exportName || '-'}</Box>
        </BootstrapTooltip>
      </TableCell>
      <TableCell sx={{ width: 400 }} align="left" size="small">
        {row.aliases ? (
          <BootstrapTooltip title={row.aliases}>
            <Box className={css.aliases}>{row.aliases}</Box>
          </BootstrapTooltip>
        ) : (
          <Box className={css.aliases}>-</Box>
        )}
      </TableCell>
      <TableCell align="left" sx={{ width: 200 }} size="small">
        <BootstrapTooltip title={row.number} placement="bottom-start">
          <Box className={css.inn}>{row.inn || row.number}</Box>
        </BootstrapTooltip>
      </TableCell>
      <TableCell align="left" sx={{ width: 200, maxWidth: 'fit-content' }}>
        <Box>{getOrdNameById(row.ord, ordList)}</Box>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          width: '57px',
        }}
      >
        <Box
          className={cn('dropDownRow', {
            _isOpen: toggle === row.uuid,
          })}
          onMouseEnter={() => {
            setToggle(row.uuid);
          }}
          onMouseLeave={() => {
            setToggle('');
          }}
        >
          <Box className="dropDownIcon">
            <FiMoreHorizontal size={24} color="#A6A6A6" />
          </Box>
          <Box className="dropDownMenu">
            {toggle === row.uuid && (
              <>
                <NavLink to={generateOrganizationPath(row.uuid)}>
                  <IconButton title="Редактировать" color="primary">
                    <ModeEditOutlinedIcon color="primary" />
                  </IconButton>
                </NavLink>
                {/* <FiArchive color={theme.palette.primary.main} size={20}  /> */}
                {/* <FiTrash2 color={theme.palette.primary.main} size={20} /> */}
              </>
            )}
          </Box>
        </Box>
      </TableCell>
    </>
  );
};

export default Item;
