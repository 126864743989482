import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { Box, Paper, TextField } from '@mui/material';
import CreativeInfo from '../CreativeInfo';
import { $creative } from '../model';
import { $ordList, getOrdNameById } from '../../../../../model/ord';
import {
  loadingStatusMapStat,
  loadingStatusMapText,
  erirStatusMapText,
  erirStatusMapStat,
} from '../../../options';
import { DateFormat } from '../../../../../../helper/methods/DateFormat';

export const Statuses: FC = () => {
  const [creative, ordList] = useUnit([$creative, $ordList]);
  if (!creative) return null;

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '30px',
      }}
    >
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap="0 30px"
        marginBottom="30px"
      >
        <CreativeInfo
          text={`${
            loadingStatusMapText.get(creative.loadingStatus) ||
            creative.loadingStatus
          } ${
            creative.loadingAt && creative.loadingAt !== 1640984400
              ? `• ${DateFormat(creative.loadingAt * 1000)}`
              : ''
          }`}
          status={loadingStatusMapStat.get(creative.loadingStatus) || null}
        />

        <CreativeInfo
          text={`${erirStatusMapText.get(creative.erirStatus)} ${
            creative.erirExportedOn
              ? `•  ${DateFormat(creative.erirExportedOn * 1000)}`
              : ''
          }`}
          status={erirStatusMapStat.get(creative.erirStatus) || null}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="0 30px">
        <TextField
          label="ORD"
          disabled
          variant="standard"
          value={getOrdNameById(creative.ord, ordList)}
          inputProps={{ maxLength: 255 }}
        />
        <TextField
          label="ORD ID"
          disabled
          variant="standard"
          value={creative.externalId || '-'}
        />
        <TextField
          label="ERID"
          disabled
          variant="standard"
          value={creative.erid || '-'}
          autoComplete="none"
        />
      </Box>
    </Paper>
  );
};
