import React, { FC } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Status } from '../../../../../apiRPC/сampaign';

function getStatus4Param(status: string | null): Status | 'ALL' {
  if (!status) return 'ACTIVE';
  return status as Status;
}

export const TabHeader: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const statusParam = searchParams.get('status');

  return (
    <Box
      sx={{
        background: '#3F55B5',
        boxShadow: '45px 0px 20px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Tabs
        value={getStatus4Param(statusParam)}
        onChange={(_, val) =>
          setSearchParams((prev) => {
            prev.set('status', val);
            return prev;
          })
        }
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab value="ACTIVE" label="Активные" />
        <Tab value="ARCHIVE" label="В архиве" />
        <Tab value="ON_APPROVAL" label="На модерации" />
        <Tab value="ALL" label="Все" />
      </Tabs>
    </Box>
  );
};
