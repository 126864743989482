import request from '../request';

export type POrganizationGet = { uuid: string };

export type TypeOrganization = 'ul' | 'fl' | 'ip' | 'ffl' | 'ful';

export type ROrganizationGet = {
  uuid: string;
  name: string;
  aliases?: string;
  inn?: string;
  type: TypeOrganization;
  ord: number;
  externalId?: string;
  mobilePhone?: string;
  epayNumber?: string;
  regNumber?: string;
  alternativeInn?: string;
  number: string;
  exportName?: string;
  oksmNumber?: string;
  main?: boolean;
  directClient: boolean;
  isRr: boolean;
  isOrs: boolean;
  isRd: boolean;
  isRa: boolean;
  rsUrl?: string;
  platforms?: string[];
  status: 'ACTIVE' | 'DELETED' | 'ON_APPROVAL';
};
export const organizationGet = request<ROrganizationGet, POrganizationGet>({
  method: 'organization.get',
});

export type ROrganizationGetList = {
  total: number;
  rows: ROrganizationGet[];
};
export type POrganizationGetList = {
  filter?: {
    limit?: number;
    offset?: number;
    search?: string;
    status?: 'ACTIVE' | 'DELETED' | 'ON_APPROVAL';
  };
};
export const organizationGetList = request<
  ROrganizationGetList,
  POrganizationGetList | undefined
>({
  method: 'organization.getList',
});

export type POrganizationCreate = {
  fields: {
    name: string;
    aliases?: string;
    inn?: string;
    type: TypeOrganization;
    ord: number;
    mobilePhone?: string;
    epayNumber?: string;
    regNumber?: string;
    alternativeInn?: string;
    exportName?: string;
    oksmNumber?: string;
    directClient?: boolean;
    isRr?: boolean;
    isOrs?: boolean;
    rsUrl?: string;
    isRd?: boolean;
    isRa?: boolean;
    platforms?: string[];
  };
};

export type ROrganizationCreate = ROrganizationGet;
export const organizationCreate = request<
  ROrganizationCreate,
  POrganizationCreate
>({
  method: 'organization.create',
});

export type POrganizationUpdate = {
  uuid: string;
  fields: {
    name?: string;
    aliases?: string;
    exportName?: string;
    isRr?: boolean;
    isOrs?: boolean;
    rsUrl?: string;
    isRd?: boolean;
    isRa?: boolean;
    platforms?: string[];
  };
};

export type ROrganizationUpdate = ROrganizationGet;
export const organizationUpdate = request<
  ROrganizationUpdate,
  POrganizationUpdate
>({
  method: 'organization.update',
});

export type ORDItem = { id: number; title: string; code: string };
export type ROrdGetList = ORDItem[];
export const ordGetList = request<ROrdGetList>({
  method: 'ord.getList',
});

export type ROrganizationGetDictionary = { label: string; value: string }[];
export const organizationGetDictionary = request<ROrganizationGetDictionary>({
  method: 'organization.getNumbersDictionary',
});

export type PlatformTypes = 'Site' | 'Application' | 'InformationSystem';
export type Platform = {
  uuid: string;
  name: string;
  url: string;
  isOwned: boolean;
  type: PlatformTypes;
  externalId?: string;
  externalCode: {
    source: string | string[];
    network: string | string[];
  };
  ord: number;
};
export type RPlatformGetList = {
  total: number;
  rows: Platform[];
};
export const platformGetList = request<RPlatformGetList>({
  method: 'platform.getList',
});

export type OrganizationPlatform = {
  uuid: string;
  name: string;
  url: string;
  ord: number;
};
export type PGetOrganizationPlatforms = { organization: string };
export type RGetOrganizationPlatforms = OrganizationPlatform[];
export const getOrganizationPlatforms = request<
  RGetOrganizationPlatforms,
  PGetOrganizationPlatforms
>({
  method: 'organization.getOrganizationPlatforms',
});
