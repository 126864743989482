import {
  Box,
  Button,
  FilterOptionsState,
  ListItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ReactRouterPrompt from 'react-router-prompt';
import Header from '../../../../components/Header';
import SelectField, { TOption } from '../../../../../UI/form/SelectField';
import 'react-datepicker/dist/react-datepicker.css';
import { SelectMS } from '../../../../../UI/form/SelectMS';
import { useCreate } from './useCreate';
import {
  actionTypeOptions,
  executorIsObligedForRegistrationOptions,
  isInvoiceContractOptions,
  optionsAgentActingForPublisher,
  optionsCategoryCreate,
  subjectTypeOptions,
  typeOptions,
  useNetworkStatOptions,
} from '../../../Organization/options';

import Modal from '../../../../../components/Modal';
import { addressesORD } from '../../../../addresses';
import CalendarField from '../../../../../components/CalendarField';
import { ContractAutocomplete } from '../../../../components/ContractAutocomplete';
import { LabelForContracts } from '../../../Campaigns/UI/LabelForContracts';
import {
  onLoadFinalContracts,
  onLoadParentContracts,
  searchFinalContract,
  searchParentContract,
} from '../model';

export function filterOptionsOrganizations<T extends TOption>(
  options: T[],
  params: FilterOptionsState<T>,
) {
  return options.filter((item) => {
    if (
      typeof item.search === 'string' &&
      item.search?.toUpperCase().includes(params.inputValue.toUpperCase())
    )
      return true;
    if (typeof item.label === 'string') {
      return item.label.toUpperCase().includes(params.inputValue.toUpperCase());
    }
    return false;
  });
}

export const Create: FC = () => {
  const {
    formik,
    handleChange,
    organizationOption,
    handleChangeForAmount,
    ordOption,
    isPrompt,
    pending,
    btnSendDisabled,
    finalContractList,
    parentContractList,
    onDefaultsParentContractList,
    onDefaultsFinalContractList,
  } = useCreate();

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Header>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h1"
            sx={{
              marginLeft: '2px',
            }}
          >
            Новый договор
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <NavLink to={addressesORD.contractsPath}>
            <Button
              variant="outlined"
              sx={{
                marginLeft: '36px',
              }}
              disabled={pending}
            >
              Отмена
            </Button>
          </NavLink>
          <LoadingButton
            loading={pending}
            type="submit"
            disabled={btnSendDisabled}
            variant="contained"
            sx={{
              marginLeft: '36px',
            }}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </Header>
      <Paper
        elevation={0}
        sx={{
          padding: '30px',
        }}
      >
        <Typography fontWeight={700} mb={3.8}>
          Параметры
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '30px',
          }}
        >
          <SelectMS
            error={Boolean(formik.errors.ord)}
            required
            label="Основная ОРД"
            value={formik.values.ord}
            name="ord"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={ordOption}
            helperText={formik.errors.ord}
          />
          <TextField
            label="Идентификатор договора в ОРД"
            variant="standard"
            value={formik.values.externalId}
            name="externalId"
            inputProps={{ maxLength: 40 }}
            autoComplete="none"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.externalId)}
            helperText={formik.errors.externalId}
          />
          <SelectField
            label="Заказчик"
            placeholder="Название / ИНН"
            options={organizationOption}
            variant="standard"
            getOptionLabel={(v) => `${v.label} ${v.search}`}
            required
            filterOptions={filterOptionsOrganizations}
            value={formik.values.customer}
            helperText={formik.errors.customer}
            onChange={(_, v) => {
              formik.setFieldValue('customer', v).then(() => {
                formik.validateField('customer');
                formik.validateField('executor');
              });
            }}
            renderOption={(props, option) => (
              <ListItem
                component="li"
                {...props}
                key={
                  typeof option.value === 'string' ? option.value : option.label
                }
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {option.label}
                <Typography
                  component="span"
                  ml={1}
                  color="rgba(166, 166, 166, 1)"
                >
                  {option.search}
                </Typography>
              </ListItem>
            )}
            error={Boolean(formik.errors.customer)}
          />

          <SelectField
            label="Исполнитель"
            placeholder="Название / ИНН"
            options={organizationOption}
            variant="standard"
            required
            filterOptions={filterOptionsOrganizations}
            getOptionLabel={(v) => `${v.label} ${v.search}`}
            value={formik.values.executor}
            helperText={formik.errors.executor}
            onChange={(_, v) => {
              formik.setFieldValue('executor', v).then(() => {
                formik.validateField('executor');
                formik.validateField('customer');
              });
            }}
            renderOption={(props, option) => (
              <ListItem
                component="li"
                {...props}
                key={
                  typeof option.value === 'string' ? option.value : option.label
                }
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {option.label}
                <Typography
                  component="span"
                  ml={1}
                  color="rgba(166, 166, 166, 1)"
                >
                  {option.search}
                </Typography>
              </ListItem>
            )}
            error={Boolean(formik.errors.executor)}
          />
          <TextField
            label="Номер договора"
            variant="standard"
            value={formik.values.number}
            name="number"
            inputProps={{ maxLength: 255 }}
            autoComplete="none"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.number)}
            helperText={formik.errors.number}
          />
          <CalendarField
            required
            label="Дата договора"
            value={formik.values.date}
            onChange={(v) => {
              formik.setFieldValue('date', v).then(() => {
                formik.validateField('date');
              });
            }}
            error={formik.errors.date}
            helperText={formik.errors.date}
          />
          <SelectMS
            error={Boolean(formik.errors.type)}
            required
            label="Тип договора"
            value={formik.values.type}
            name="type"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={typeOptions.filter(
              (item) => item.value !== 'SelfPromotion',
            )}
            helperText={formik.errors.type}
          />

          <SelectMS
            error={Boolean(formik.errors.category)}
            required
            label="Категория договора"
            value={formik.values.category || ''}
            name="category"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={optionsCategoryCreate}
            helperText={formik.errors.category}
          />
          <SelectMS
            error={Boolean(formik.errors.agentActingForPublisher)}
            required
            label="Направление денежных средств"
            value={formik.values.agentActingForPublisher ?? ''}
            name="agentActingForPublisher"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={optionsAgentActingForPublisher}
            helperText={formik.errors.agentActingForPublisher}
          />

          <Box
            sx={{
              position: 'relative',
            }}
          >
            <ContractAutocomplete
              options={finalContractList}
              value={formik.values.finalContract}
              onInputChange={(event, value, reason) => {
                if (reason === 'clear') onDefaultsFinalContractList();
              }}
              sx={{
                '.MuiInputLabel-root': { zIndex: 99 },
              }}
              label={
                <LabelForContracts
                  text="Доходный договор"
                  required={
                    formik.values.ord === 3 &&
                    formik.values.category === 'Initial'
                  }
                  title="№ Договора, ИНН"
                />
              }
              placeholder="Название / ИНН"
              onChange={(_, v) => {
                formik.setFieldValue('finalContract', v).then(() => {
                  formik.validateField('finalContract');
                });
              }}
              error={Boolean(formik.errors.finalContract)}
              helperText={formik.errors.finalContract}
              onScrollEnd={onLoadFinalContracts}
              search={searchFinalContract}
              getOptionLabel={(option) =>
                `${option?.number} • ${option?.customerInn}`
              }
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  key={option?.uuid}
                  component="li"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {option?.number} • {option?.customerInn}
                </ListItem>
              )}
            />
          </Box>

          <Box
            sx={{
              position: 'relative',
            }}
          >
            <ContractAutocomplete
              options={parentContractList}
              value={formik.values.parent}
              onInputChange={(event, value, reason) => {
                if (reason === 'clear') onDefaultsParentContractList();
              }}
              sx={{
                '.MuiInputLabel-root': { zIndex: 99 },
              }}
              label={
                <LabelForContracts
                  text="Родительский договор"
                  required={formik.values.type === 'Additional'}
                  title="№ Договора, ИНН"
                />
              }
              placeholder="Название / ИНН"
              onChange={(_, v) => {
                formik.setFieldValue('parent', v).then(() => {
                  formik.validateField('parent');
                });
              }}
              error={Boolean(formik.errors.parent)}
              helperText={formik.errors.parent}
              onScrollEnd={onLoadParentContracts}
              search={searchParentContract}
              getOptionLabel={(option) =>
                `${option?.number} • ${option?.customerInn}`
              }
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  key={option?.uuid}
                  component="li"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {option?.number} • {option?.customerInn}
                </ListItem>
              )}
            />
          </Box>

          <SelectMS
            error={Boolean(formik.errors.actionType)}
            required
            label="Действия, осуществляемые посредником-представителем"
            value={formik.values.actionType}
            name="actionType"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={actionTypeOptions}
            disabledOption={(value) =>
              formik.values.type === 'Intermediary' && value === 'None'
            }
            helperText={formik.errors.actionType}
          />
          <SelectMS
            error={Boolean(formik.errors.isInvoiceContract)}
            label="Договор отчета"
            value={formik.values.isInvoiceContract}
            name="isInvoiceContract"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={isInvoiceContractOptions}
            helperText={formik.errors.isInvoiceContract}
          />
          <SelectMS
            error={Boolean(formik.errors.subjectType)}
            required
            label="Сведения о предмете договора"
            value={formik.values.subjectType}
            name="subjectType"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={subjectTypeOptions}
            helperText={formik.errors.subjectType}
          />
          <TextField

            variant="standard"
            value={formik.values.amount}
            name="amount"
            inputProps={{
              maxLength: 30,
            }}
            autoComplete="none"
            error={Boolean(formik.errors.amount)}
            helperText={formik.errors.amount}
            onChange={handleChangeForAmount}
            onBlur={formik.handleBlur}
            label={
              <LabelForContracts text="Сумма договора (руб)" title="Указывайте итоговую сумму с учетом всех налогов" />
            }
          />
          <SelectMS
            label="Обязанность регистрировать и репортить креативы исполнителем"
            value={formik.values.executorIsObligedForRegistration}
            name="executorIsObligedForRegistration"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={executorIsObligedForRegistrationOptions}
          />
          <SelectMS
            label="Отчет на основе network"
            value={formik.values.useNetworkStat}
            name="useNetworkStat"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={useNetworkStatOptions}
          />
        </Box>
      </Paper>

      <ReactRouterPrompt when={isPrompt}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            open={isActive}
            handleClose={onCancel}
            onConfirm={onConfirm}
            title="Подтвердите переход"
            outlinedText="Остаться на этой странице"
            containedText="Покинуть эту страницу"
          >
            Вы действительно хотите покинуть страницу?
          </Modal>
        )}
      </ReactRouterPrompt>
    </Box>
  );
};
