import { Box, Checkbox, IconButton, Typography, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import React, { FC, useCallback, useState } from 'react';
import { FiArchive, FiMoreHorizontal, FiCopy } from 'react-icons/fi';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { useStoreMap, useUnit } from 'effector-react';
import css from './styles.module.scss';
import { BootstrapTooltip } from '../../../../../components/BootstrapTooltip';
import { CreativeItem } from '../../../../../apiRPC/creative';
import { $ordList } from '../../../../model/ord';
import { generateCreativePath } from '../../../../addresses';
import {
  $isPendingCopyCreative,
  $isPendingCopyCreatives,
  $selectedCreatives,
  onRemoveCreative,
  onSelectCreative,
  onSingleCopyCreative,
} from '../model/copy';

const loadingStatusMap = new Map<CreativeItem['loadingStatus'], string>([
  ['NONE', 'Не отправляется'],
  ['PENDING', 'Ожидает выгрузки'],
  ['PENDING_RESOURCE', 'Ожидает выгрузки ресурсов'],
  ['SUCCESS', 'Выгружен'],
  ['ERROR', 'Ошибка'],
  ['ON_APPROVAL', 'На модерации'],
]);

type props = {
  row: CreativeItem;
};

const Item: FC<props> = ({ row }) => {
  const [isPendingCopyCreatives, isPendingCopyCreative] = useUnit([
    $isPendingCopyCreatives,
    $isPendingCopyCreative,
  ]);
  const [toggle, setToggle] = useState('');
  const theme = useTheme();

  const ord = useStoreMap({
    store: $ordList,
    keys: [row.ord],
    fn: (state, key) => state.find((item) => item.id === key[0])?.title,
  });

  const checked = useStoreMap({
    store: $selectedCreatives,
    keys: [row.uuid],
    fn: (state, key) => state.includes(key[0]),
  });

  const tooltipErir = useCallback((state: string) => {
    if (state === 'SUCCESS') {
      return 'Экспортировано в ЕРИР';
    }
    if (state === 'PENDING') {
      return 'Запланирован экспорт в ЕРИР';
    }
    if (state === 'ERROR') {
      return 'Ошибка';
    }
    return 'Не отправляется в ЕРИР';
  }, []);

  return (
    <>
      <TableCell component="th" scope="row" sx={{ width: 30 }}>
        <Checkbox
          size="small"
          checked={checked}
          disabled={isPendingCopyCreatives}
          onChange={(e) => {
            e.stopPropagation();
            const val = e.target.checked;
            if (!val) {
              onRemoveCreative(row.uuid);
              return;
            }
            onSelectCreative(row.uuid);
          }}
        />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{ width: 100, maxWidth: 'fit-content' }}
      >
        <Box display="flex" alignItems="center" gap="0 10px">
          <BootstrapTooltip title={tooltipErir(row.erirStatus)} placement="top">
            <Box
              className={cn(css.status, {
                _isSuccess: row.erirStatus === 'SUCCESS',
                _isWarring: row.erirStatus === 'PENDING',
                _isError: row.erirStatus === 'ERROR',
              })}
            />
          </BootstrapTooltip>

          <Box>{row.erid || '-'}</Box>
        </Box>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        size="small"
        sx={{ width: 600, maxWidth: 'fit-content' }}
      >
        <Box
          sx={{
            maxWidth: '600px',
          }}
        >
          <BootstrapTooltip title={row.name} placement="top-start">
            <Box className={css.name}>{row.name}</Box>
          </BootstrapTooltip>

          <Typography
            sx={{
              color: theme.palette.secondary.light,
              fontSize: '14px',
              maxWidth: '400px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {row.uuid} • {row.externalId}
          </Typography>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row" width={400}>
        <BootstrapTooltip title={row.campaign.name} placement="top">
          <Box>{row.campaign.name}</Box>
        </BootstrapTooltip>
      </TableCell>
      <TableCell component="th" scope="row">
        <Box
          sx={{
            maxwidth: '430px',
          }}
        >
          <Box className={css.name}>{row.type}</Box>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row">
        <Box
          sx={{
            maxWidth: '200px',
          }}
        >
          <Box className={css.name}>{row.form}</Box>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row" width={150}>
        {ord}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box className={cn(css.api)}>
          {loadingStatusMap.get(row.loadingStatus) || row.loadingStatus}
        </Box>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          width: '57px',
        }}
      >
        <Box
          className={cn('dropDownRow', {
            _isOpen: toggle === row.uuid,
          })}
          onMouseEnter={() => {
            setToggle(row.uuid);
          }}
          onMouseLeave={() => {
            setToggle('');
          }}
        >
          <Box className="dropDownIcon">
            <FiMoreHorizontal size={24} color="#A6A6A6" />
          </Box>
          <Box className="dropDownMenu">
            {toggle === row.uuid && (
              <>
                <BootstrapTooltip title="копировать" placement="top">
                  <IconButton
                    disabled={isPendingCopyCreative}
                    color="primary"
                    onClick={() => onSingleCopyCreative(row.uuid)}
                  >
                    <FiCopy size={24} color="#4B61BF" />
                  </IconButton>
                </BootstrapTooltip>
                <NavLink to={generateCreativePath(row.uuid)}>
                  <IconButton title="Редактировать" color="primary">
                    <ModeEditOutlinedIcon color="primary" />
                  </IconButton>
                </NavLink>
              </>
            )}
          </Box>
        </Box>
      </TableCell>
    </>
  );
};

export default Item;
