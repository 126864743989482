import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useUnit } from 'effector-react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  ComputeItemKey,
  FixedFooterContent,
  FixedHeaderContent,
  ItemContent,
  TableComponents,
  TableVirtuoso,
  VirtuosoHandle,
} from 'react-virtuoso';
import { Box, TableSortLabel } from '@mui/material';
import Item from './Item';
import css from './styles.module.scss';
import { FabSquare } from '../../../../../UI/FabSquare';

import { ReactComponent as StatusSvg } from '../../../../../assets/icons/status.svg';
import { BootstrapTooltip } from '../../../../../components/BootstrapTooltip';

import Loading from '../../../../../UI/Loading';
import { navigateForEffect } from '../../../../../model/routing';
import { generateCampaignPath } from '../../../../addresses';
import { ReactComponent as SortSvg } from '../../../../../assets/icons/sort.svg';
import {
  $campaignList,
  $campaignStatus,
  campaignGetListForScrollFX,
  onLoadCampaign,
  onSendCampaignStatus,
  resetCampaignStatus,
} from '../model';
import { CampaignItem, RCampaignGetList } from '../../../../../apiRPC/сampaign';
import { $sortData, setSort } from '../model/filter/sort';
import Modal from '../../../../../components/Modal';

const textForModalSt: {
  [key: string]: { title: string; description: string; containedText: string };
} = {
  ACTIVE: {
    title: 'Извлечение из архива',
    description: 'Вы действительно хотите извлечь из архива кампанию',
    containedText: 'Извлечь из архива',
  },
  ARCHIVE: {
    title: 'Добавление в архив',
    description: 'Вы действительно хотите добавить в архив кампанию',
    containedText: 'Добавить в архив',
  },
  DELETED: {
    title: 'Удалить',
    description: 'Вы действительно хотите удалить кампанию',
    containedText: 'Удалить',
  },
};

const ItemVirtual: ItemContent<CampaignItem, CampaignItem[]> = (index, row) => (
  <Item row={row} key={row.uuid} />
);

const computeItemKey: ComputeItemKey<CampaignItem, CampaignItem[]> = (_, d) =>
  d.uuid;

export type DataRow = {
  status: {
    value: 'success' | 'warning' | 'empty';
    tooltip: string;
  };
  name: string;
  uuid: string;
  date: string;
  customer: string;
  inn?: string;
  executor: string;
  ord: number;
  api: boolean;
};

const Footer: FixedFooterContent = () => {
  const pending = useUnit(campaignGetListForScrollFX.pending);
  if (!pending) return null;
  return (
    <TableRow>
      <TableCell colSpan={8}>
        <Box display="flex" justifyContent="center">
          <Loading />
        </Box>
      </TableCell>
    </TableRow>
  );
};

type SortComponentProps = { className: string };
const SortComponent: FC<SortComponentProps> = ({ className }) => (
  <Box className={`${css.sort} ${className}`}>
    <SortSvg />
  </Box>
);

const DefaultComponent: FC = () => (
  <Box className={`${css.default}`}>
    <SortSvg />
  </Box>
);

const components: TableComponents<RCampaignGetList['rows']> = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      stickyHeader
      sx={{ minWidth: 650 }}
      aria-label="simple table"
    />
  ),
  TableHead,
  TableRow: ({ context, ...props }) => {
    const currentRow = context?.at(props['data-index']);
    const onClickRow: MouseEventHandler<HTMLTableRowElement> = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!currentRow?.uuid) return;
      navigateForEffect(generateCampaignPath(currentRow.uuid));
    };
    return (
      <TableRow
        sx={{
          cursor: 'pointer',
          '&:last-child td, &:last-child th': { border: 0 },
        }}
        {...props}
        onDoubleClick={onClickRow}
      />
    );
  },
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
  TableFoot: TableFooter,
};

const fixedHeader: FixedHeaderContent = () => {
  const sortData = useUnit($sortData);
  return (
    <TableRow>
      <TableCell align="center">
        <BootstrapTooltip title="Статус" placement="top">
          <StatusSvg />
        </BootstrapTooltip>
      </TableCell>
      <TableCell>Название</TableCell>
      <TableCell align="left">Изначальный договор</TableCell>
      <TableCell align="left">Договор отчета</TableCell>
      <TableCell align="left">Дата начала</TableCell>
      <TableCell align="left">Дата окончания</TableCell>
      <TableCell align="left">
        <TableSortLabel
          active={Boolean(sortData)}
          direction={sortData || undefined}
          onClick={() => {
            if (sortData === 'desc') setSort('asc');
            if (sortData === 'asc') setSort(null);
            if (sortData === null) setSort('desc');
          }}
          IconComponent={sortData === null ? DefaultComponent : SortComponent}
        >
          Бюджет
        </TableSortLabel>
      </TableCell>
      <TableCell align="center" />
    </TableRow>
  );
};
const onMore = () => onLoadCampaign();
export const BasicTable: FC = () => {
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [showBtn, setShowBtn] = useState(false);
  const onTop = () => {
    virtuosoRef.current?.scrollToIndex({
      index: 0,
      behavior: 'smooth',
    });
  };

  const campaignList = useUnit($campaignList);
  const campaignStatus = useUnit($campaignStatus);

  return (
    <>
      <TableVirtuoso
        ref={virtuosoRef}
        data={campaignList}
        context={campaignList}
        components={components}
        fixedHeaderContent={fixedHeader}
        itemContent={ItemVirtual}
        endReached={onMore}
        computeItemKey={computeItemKey}
        onScroll={(e) => {
          if (e.currentTarget.scrollTop > 300) {
            setShowBtn(true);
            return;
          }
          setShowBtn(false);
        }}
        fixedFooterContent={Footer}
      />

      {showBtn && (
        <FabSquare
          color="primary"
          sx={{
            right: 0,
            bottom: 35,
            position: 'absolute',
            opacity: 0.4,
          }}
          onClick={onTop}
          className={css.tabToTop}
        >
          <ExpandLessIcon />
        </FabSquare>
      )}
      <Box
        sx={{
          marginBottom: '24px',
        }}
      />

      <Modal
        open={Boolean(campaignStatus)}
        handleClose={resetCampaignStatus}
        onConfirm={onSendCampaignStatus}
        title={
          campaignStatus ? textForModalSt[campaignStatus.status]?.title : ''
        }
        outlinedText="Отмена"
        containedText={
          campaignStatus
            ? textForModalSt[campaignStatus.status]?.containedText
            : ''
        }
      >
        {campaignStatus && (
          <>
            {textForModalSt[campaignStatus.status]?.description}{' '}
            <b>{campaignStatus.name}</b>
          </>
        )}
      </Modal>
    </>
  );
};
