import { FC } from 'react';
import Box from '@mui/material/Box';
import { variant } from '@effector/reflect';
import * as React from 'react';
import { Container } from '@mui/material';
import { BasicTable } from './Table';
import { OrgHeader } from './OrgHeader';
import {
  $fieldType,
  onOrganizationGetList,
  organizationGetListFX,
  resetOrganizations,
} from './model';
import { Loadding } from '../../components/Loadding';
import { Empty } from '../../components/Errors/Empty';
import { NoConnect } from '../../components/Errors/NoConnect/NoConnect';
import { DrawerHeader } from '../../../components/DrawerHeader';
import TabHeader from './TabHeader';

export const ComponentTable = variant({
  source: $fieldType,
  cases: {
    Success: BasicTable,
    loading: Loadding,
    ErrorEmpty: Empty,
    ErrorNoContent: NoConnect,
  },
  default: Loadding,
  hooks: {
    mounted: () => {
      onOrganizationGetList();
    },
    unmounted: resetOrganizations,
  },
});

export const Organizations: FC = () => (
  <Box sx={{ width: '100%', minWidth: 'auto' }}>
    <DrawerHeader />
    <TabHeader />
    <Container maxWidth={false} sx={{ width: '100%', minWidth: 'auto' }}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        height="calc(100vh - 108px)"
      >
        <OrgHeader />
        <ComponentTable />
      </Box>
    </Container>
  </Box>
);
