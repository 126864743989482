import { combine, createEvent, createStore, sample } from 'effector';
import { createEffect } from 'effector/effector.umd';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { errorHandlerFx } from '../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../model/JWT';
import {
  creativeCreate,
  creativeGet,
  CreativeItem,
  creativesCreate,
  creativesGet,
  PCreativeCreate,
} from '../../../../../../apiRPC/creative';
import { $creativeList, onGetListCreative } from '../index';

export const onSelectCreative = createEvent<string>();
export const onRemoveCreative = createEvent<string>();
export const resetSelectedCreatives = createEvent();

export const $selectedCreatives = createStore<string[]>([])
  .on(onSelectCreative, (state, id) => {
    if (state.includes(id)) {
      return undefined;
    }
    return [...state, id];
  })
  .on(onRemoveCreative, (state, id) => state.filter((item) => item !== id))
  .reset(resetSelectedCreatives);

const creativesCreateFX = errorHandlerFx(createEffectJWT(creativesCreate));
const creativesGetFX = errorHandlerFx(createEffectJWT(creativesGet));
const creativeCreateFX = errorHandlerFx(createEffectJWT(creativeCreate));
const creativeGetFX = errorHandlerFx(createEffectJWT(creativeGet));

const multipleCopyCreativesFX = createEffect(async (data: CreativeItem[]) => {
  try {
    const creatives = await creativesGetFX(
      data.map((item) => ({ uuid: item.uuid })),
    );

    const params: PCreativeCreate[] = [];

    const postFix = '_Копия';
    const maxlenght = 255 - postFix.length;

    creatives.forEach(({ result }) => {
      if (!result) return;

      const name =
        result.name.length > maxlenght
          ? result.name.slice(0, maxlenght)
          : result.name;
      params.push({
        fields: {
          name: `${name}${postFix}`,
          campaign: result.campaign,
          creativeOkveds: result.creativeOkveds,
          description: result.description,
          type: result.type,
          form: result.form,
          targetAudienceDescription: result.targetAudienceDescription,
          url: result.url?.length ? result.url : undefined,
          html: result.html,
          defaultResourceDescription: result.defaultResourceDescription,
          shop: result.shop,
        },
      });
    });

    const result = await creativesCreateFX(params);

    result.forEach((item) => {
      if (item.error) {
        toast.error(item.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
    return result;
  } catch (error) {
    console.error(error);
    toast.error('произошла ошибка при копировании', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
    throw error;
  }
});

export const onCopyCreatives = createEvent();

export const $copyCreatives = combine(
  { selectedCreatives: $selectedCreatives, creativeList: $creativeList },
  ({ selectedCreatives, creativeList }) =>
    creativeList.filter((item) => selectedCreatives.includes(item.uuid)),
);

export const $isPendingCopyCreatives = multipleCopyCreativesFX.pending;

sample({
  source: $copyCreatives,
  clock: onCopyCreatives,
  target: multipleCopyCreativesFX,
});

sample({
  clock: multipleCopyCreativesFX.doneData,
  target: createEffect(() => {
    onGetListCreative();
    resetSelectedCreatives();
  }),
});

export const onSingleCopyCreative = createEvent<string>();

const copyCreativeFX = createEffect(async (uuid: string) => {
  try {
    const creative = await creativeGetFX({ uuid });

    const postFix = '_Копия';
    const maxlenght = 255 - postFix.length;
    const name =
      creative.name.length > maxlenght
        ? creative.name.slice(0, maxlenght)
        : creative.name;
    const params: PCreativeCreate = {
      fields: {
        name: `${name}${postFix}`,
        campaign: creative.campaign,
        creativeOkveds: creative.creativeOkveds,
        description: creative.description,
        type: creative.type,
        form: creative.form,
        targetAudienceDescription: creative.targetAudienceDescription,
        url: creative.url?.length ? creative.url : undefined,
        html: creative.html,
        defaultResourceDescription: creative.defaultResourceDescription,
        shop: creative.shop,
      },
    };

    const result = await creativeCreateFX(params);

    return result;
  } catch (error) {
    console.error(error);
    toast.error('произошла ошибка при копировании', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
    throw error;
  }
});

export const $isPendingCopyCreative = copyCreativeFX.pending;

sample({
  clock: onSingleCopyCreative,
  target: copyCreativeFX,
});

sample({
  clock: copyCreativeFX.doneData,
  target: onGetListCreative,
});
