import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useUnit } from 'effector-react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  ComputeItemKey,
  FixedFooterContent,
  FixedHeaderContent,
  ItemContent,
  TableComponents,
  TableVirtuoso,
  VirtuosoHandle,
} from 'react-virtuoso';
import { Box, Menu, MenuItem } from '@mui/material';
import Item from './Item';
import css from './styles.module.scss';
import { FabSquare } from '../../../../../UI/FabSquare';

import { ReactComponent as StatusSvg } from '../../../../../assets/icons/status.svg';
import { BootstrapTooltip } from '../../../../../components/BootstrapTooltip';
import {
  $contractList,
  contractGetListForScrollFX,
  onLoadContracts,
} from '../model';
import {
  ContractItem,
  RContractGetList,
} from '../../../../../apiRPC/contracts';
import Loading from '../../../../../UI/Loading';
import { navigateForEffect } from '../../../../../model/routing';
import { generateContractPath } from '../../../../addresses';

const ItemVirtual: ItemContent<ContractItem, ContractItem[]> = (index, row) => (
  <Item row={row} key={row.uuid} />
);

const computeItemKey: ComputeItemKey<ContractItem, ContractItem[]> = (_, d) =>
  d.uuid;

export type DataRow = {
  status: {
    value: 'success' | 'warning' | 'empty';
    tooltip: string;
  };
  name: string;
  uuid: string;
  date: string;
  customer: string;
  inn?: string;
  executor: string;
  ord: number;
  api: boolean;
};

const Footer: FixedFooterContent = () => {
  const pending = useUnit(contractGetListForScrollFX.pending);
  if (!pending) return null;
  return (
    <TableRow>
      <TableCell colSpan={8}>
        <Box display="flex" justifyContent="center">
          <Loading />
        </Box>
      </TableCell>
    </TableRow>
  );
};

const components: TableComponents<RContractGetList['rows']> = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      stickyHeader
      sx={{ minWidth: 650 }}
      aria-label="simple table"
    />
  ),
  TableHead,
  TableRow: ({ context, ...props }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);

    const open = Boolean(anchorEl);

    const handleRightClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
      if (e.target instanceof Element && e.target?.nodeName === 'A') {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      setMouseX(e.clientX);
      setMouseY(e.clientY);
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const currentRow = context?.at(props['data-index']);
    const onClickRow: MouseEventHandler<HTMLTableRowElement> = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!currentRow?.uuid) return;
      navigateForEffect(generateContractPath(currentRow.uuid));
    };

    const onOpenNewTab: React.MouseEventHandler<HTMLLIElement> = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!currentRow?.uuid) return;
      const newTab = window.open(generateContractPath(currentRow.uuid));
      window.focus();
      newTab?.blur();
      handleClose();
    };

    const handleMiddleClick: MouseEventHandler<HTMLTableRowElement> = (
      event,
    ) => {
      if (event.button === 1) {
        if (!currentRow?.uuid) return;
        const newTab = window.open(generateContractPath(currentRow.uuid));
        window.focus();
        newTab?.blur();
      }
    };
    return (
      <>
        <TableRow
          sx={{
            cursor: 'pointer',
            '&:last-child td, &:last-child th': { border: 0 },
          }}
          {...props}
          onDoubleClick={onClickRow}
          onContextMenu={handleRightClick}
          onMouseDown={handleMiddleClick}
        />

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: mouseY, left: mouseX }}
          BackdropProps={{
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
            sx: { opacity: '0!important' },
          }}
        >
          <MenuItem sx={{ padding: 1, fontSize: 14 }} onClick={onOpenNewTab}>
            Открыть в новой вкладке
          </MenuItem>
        </Menu>
      </>
    );
  },
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
  TableFoot: TableFooter,
};

const fixedHeader: FixedHeaderContent = () => (
  <TableRow>
    <TableCell align="left">Статус</TableCell>
    <TableCell align="center">
      <BootstrapTooltip title="Статус ЕРИР" placement="top">
        <StatusSvg />
      </BootstrapTooltip>
    </TableCell>
    <TableCell>Название</TableCell>
    <TableCell align="left">Дата</TableCell>
    <TableCell align="left">Заказчик</TableCell>
    <TableCell align="left">Исполнитель</TableCell>
    <TableCell align="left">ОРД</TableCell>
    <TableCell align="left">API</TableCell>
    <TableCell align="center" />
  </TableRow>
);
const onMore = () => onLoadContracts();
export const BasicTable: FC = () => {
  const contractList = useUnit($contractList);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [showBtn, setShowBtn] = useState(false);
  const onTop = () => {
    virtuosoRef.current?.scrollToIndex({
      index: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <TableVirtuoso
        ref={virtuosoRef}
        data={contractList || []}
        context={contractList || []}
        components={components}
        fixedHeaderContent={fixedHeader}
        itemContent={ItemVirtual}
        endReached={onMore}
        computeItemKey={computeItemKey}
        onScroll={(e) => {
          if (e.currentTarget.scrollTop > 300) {
            setShowBtn(true);
            return;
          }
          setShowBtn(false);
        }}
        fixedFooterContent={Footer}
      />
      {showBtn && (
        <FabSquare
          color="primary"
          sx={{
            right: 0,
            bottom: 35,
            position: 'absolute',
            opacity: 0.4,
          }}
          onClick={onTop}
          className={css.tabToTop}
        >
          <ExpandLessIcon />
        </FabSquare>
      )}
      <Box
        sx={{
          marginBottom: '24px',
        }}
      />
    </>
  );
};
